import React from "react";

// reactstrap components
import {
    // Button,
    Card,
    CardHeader,
    //CardTitle,
    CardBody,
    FormGroup,
    //Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
class valve_repair extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_submit_click: 0,
            body: "",
            bonnet: "",
            plug_disc_ball: "",
            body_notes: "",
            bonnet_notes: "",
            plug_disc_ball_select: "",
            plug_disc_ball_notes: "",
            shaft_stem: "Stem",
            shaft_stem_select: "",
            stem_notes: "",
            metal_ring_option: "",
            metal_ring: "",
            metal_ring_notes: "",
            soft_ring: "",
            soft_ring_notes: "",
            cage: "",
            cage_notes: "",
            gasket: "",
            gasket_notes: "",
            glande_packing: "",
            glande_packing_notes: "",
            seal_ring: "",
            seal_ring_notes: "",
            glande_fastners: "",
            glande_fastners_notes: "",
            body_fastners: "",
            body_fastners_notes: "",
            other: "",
            page_path: "Dashboard",
            is_valve_applicable: 1,
            bellow: "",
            bellow_notes: "",
        };
        this.valveRepairSubmit = this.valveRepairSubmit.bind(this);
        this.GoToDashboard = this.GoToDashboard.bind(this);
        this.handleModuleChange = this.handleModuleChange.bind(this);
        this.onChangeplug = this.onChangeplug.bind(this);
        this.onChangebodynotes = this.onChangebodynotes.bind(this);
        this.onChangebonnetnotes = this.onChangebonnetnotes.bind(this);
        this.onChangeplug_notes = this.onChangeplug_notes.bind(this);
        this.onChangeshaft = this.onChangeshaft.bind(this);
        this.onChangeMetalOption = this.onChangeMetalOption.bind(this);
        this.onChangestem_note = this.onChangestem_note.bind(this);
        this.onChangemetal_note = this.onChangemetal_note.bind(this);
        this.onChangesoftnotes = this.onChangesoftnotes.bind(this);
        this.onChangecagenotes = this.onChangecagenotes.bind(this);
        this.onChangegasketnotes = this.onChangegasketnotes.bind(this);
        this.onChangeglandenotes = this.onChangeglandenotes.bind(this);
        this.onChangeSeatRingNotes = this.onChangeSeatRingNotes.bind(this);
        this.onChangeGlandeFastnersNotes = this.onChangeGlandeFastnersNotes.bind(this);
        this.onChangebodyfastnotes = this.onChangebodyfastnotes.bind(this);
        this.onChangeBody = this.onChangeBody.bind(this);
        this.onChangeBonnet = this.onChangeBonnet.bind(this);
        this.onChangePlugSelect = this.onChangePlugSelect.bind(this);
        this.onChangeStem = this.onChangeStem.bind(this);
        this.onChangeMetal = this.onChangeMetal.bind(this);
        this.onChangeSoftRing = this.onChangeSoftRing.bind(this);
        this.onChangeCage = this.onChangeCage.bind(this);
        this.onChangeGasket = this.onChangeGasket.bind(this);
        this.onChangeGlandePack = this.onChangeGlandePack.bind(this);
        this.onChangeSealRing = this.onChangeSealRing.bind(this);
        this.onChangeGlandeFastner = this.onChangeGlandeFastner.bind(this);
        this.onChangeBodyFastner = this.onChangeBodyFastner.bind(this);
        this.onChangeother = this.onChangeother.bind(this);
        this.PreviousClick = this.PreviousClick.bind(this);
        this.NextClick = this.NextClick.bind(this);
        this.NextSaveClick = this.NextSaveClick.bind(this);
        this.PrevSaveClick = this.PrevSaveClick.bind(this);
        this.onChangeValveapplicable = this.onChangeValveapplicable.bind(this);
        this.onChangeBellow = this.onChangeBellow.bind(this);
        this.onChangebellownotes = this.onChangebellownotes.bind(this);
    }
    GoToDashboard() {
        this.props.history.push('./Dashboard');
    }
    PreviousClick(){
        this.props.history.push('./general_services');
    }
    NextClick(){
        this.props.history.push('./actuator_repair');
    }
    NextSaveClick(){
        this.setState({
            page_path: "actuator_repair",
        });
        this.valveRepairSubmit();
    }   
    PrevSaveClick(){
        this.setState({
            page_path: "general_services",
        });
        this.valveRepairSubmit();
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const jobData = {
                job_id: localStorage['job_id'],
                module_name: "job_valve_repair"
            }
            axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    this.setState({
                        body: result.data.job_detail.body,
                        body_notes: result.data.job_detail.body_notes,
                        bonnet: result.data.job_detail.bonnet,
                        bonnet_notes: result.data.job_detail.bonnet_notes,
                        plug_disc_ball: result.data.job_detail.plug_disc_ball,
                        plug_disc_ball_select: result.data.job_detail.plug_disc_ball_select,
                        plug_disc_ball_notes: result.data.job_detail.plug_disc_ball_notes,
                        shaft_stem: result.data.job_detail.shaft_stem,
                        shaft_stem_select: result.data.job_detail.shaft_stem_select,
                        stem_notes: result.data.job_detail.stem_notes,
                        metal_ring_option: result.data.job_detail.metal_ring_option,
                        metal_ring: result.data.job_detail.metal_ring,
                        metal_ring_notes: result.data.job_detail.metal_ring_notes,
                        soft_ring: result.data.job_detail.soft_ring,
                        soft_ring_notes: result.data.job_detail.soft_ring_notes,
                        cage: result.data.job_detail.cage,
                        cage_notes: result.data.job_detail.cage_notes,
                        gasket: result.data.job_detail.gasket,
                        gasket_notes: result.data.job_detail.gasket_notes,
                        glande_packing: result.data.job_detail.glande_packing,
                        glande_packing_notes: result.data.job_detail.glande_packing_notes,
                        seal_ring: result.data.job_detail.seal_ring,
                        seal_ring_notes: result.data.job_detail.seal_ring_notes,
                        glande_fastners: result.data.job_detail.glande_fastners,
                        glande_fastners_notes: result.data.job_detail.glande_fastners_notes,
                        body_fastners: result.data.job_detail.body_fastners,
                        body_fastners_notes: result.data.job_detail.body_fastners_notes,
                        other: result.data.job_detail.other,

                        bellow: result.data.job_detail.bellow,
                        bellow_notes: result.data.job_detail.bellow_notes,
                        is_valve_applicable: result.data.job_detail.is_valve_applicable,
                    });
                }).catch(function (error) {
                    //console.log(error);
                });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate () {
        if (document.querySelector('input[name=is_valve_applicable]:checked').value == 2) {
            // $("#form textarea[name$='other']").attr('readonly','true');

            $("#form select").val('N/A');
            $("#form select").attr('disabled','disabled');
            $("#form input").attr('readonly','true');
            $("input[name$='plug_disc_ball']").attr('disabled','disabled');
            $("input[name$='shaft_stem'],input[name$='metal_ring_option']").attr('disabled','disabled');
        }else{
            $("#form select").removeAttr('disabled');
            $("#form input").removeAttr('readonly');
            $("input[name$='plug_disc_ball']").removeAttr('disabled');
            $("input[name$='shaft_stem'],input[name$='metal_ring_option']").removeAttr('disabled');
            // $("textarea[name$='other']").removeAttr('readonly');
        }
    }
    //select module
    handleModuleChange(e) {
        if (this.refs.select_module.value === 'Customer & Job') {
            this.props.history.push('./Customer');
        }
        else if (this.refs.select_module.value === 'Problems & Open Inspection') {
            this.props.history.push('./open_inspection');
        }
        else if (this.refs.select_module.value === 'Open Inspection Image') {
            this.props.history.push('./open_inspection_image');
        }
        else if (this.refs.select_module.value === 'General Service') {
            this.props.history.push('./general_services');
        }
        else if (this.refs.select_module.value === 'Valve Repair') {
            this.props.history.push('./valve_repair');
        }
        else if (this.refs.select_module.value === 'Actuator Repair') {
            this.props.history.push('./actuator_repair');
        }
        else if (this.refs.select_module.value === 'Accessories Detail') {
            this.props.history.push('./accessories_detail');
        }
        else if (this.refs.select_module.value === 'Valve Testing') {
            this.props.history.push('./valve_testing');
        }
        else if (this.refs.select_module.value === 'Repaired Images') {
            this.props.history.push('./repaired_image');
        }
        else if (this.refs.select_module.value === 'Dispatch') {
            this.props.history.push('./dispatch');
        }
    }
    onChangeValveapplicable(e) {
        this.setState({
            is_valve_applicable: parseInt(e.target.value),
        });
    }
    onChangeBellow(e) {
        this.setState({
            bellow: e.target.value,
        });
    }
    onChangebellownotes(e) {
        this.setState({
            bellow_notes: e.target.value,
        });
    }
    onChangeplug(e) {
        this.setState({
            plug_disc_ball: e.target.value,
        });
    }
    onChangeBody(e) {
        this.setState({
            body: e.target.value,
        });
    }
    onChangebodynotes(e) {
        this.setState({
            body_notes: e.target.value,
        });
    }
    onChangeBonnet(e) {
        this.setState({
            bonnet: e.target.value,
        });
    }
    onChangebonnetnotes(e) {
        this.setState({
            bonnet_notes: e.target.value,
        });
    }
    onChangePlugSelect(e) {
        this.setState({
            plug_disc_ball_select: e.target.value,
        });
    }
    onChangeplug_notes(e) {
        this.setState({
            plug_disc_ball_notes: e.target.value,
        });
    }
    onChangeshaft(e) {
        this.setState({
            shaft_stem: e.target.value,
        });
    }
    onChangeStem(e) {
        this.setState({
            shaft_stem_select: e.target.value,
        });
    }
    onChangestem_note(e) {
        this.setState({
            stem_notes: e.target.value,
        });
    }
    onChangeMetalOption(e) {
        this.setState({
            metal_ring_option: e.target.value,
        });
    }
    onChangeMetal(e) {
        this.setState({
            metal_ring: e.target.value,
        });
    }
    onChangemetal_note(e) {
        this.setState({
            metal_ring_notes: e.target.value,
        });
    }
    onChangeSoftRing(e) {
        this.setState({
            soft_ring: e.target.value,
        });
    }
    onChangesoftnotes(e) {
        this.setState({
            soft_ring_notes: e.target.value,
        });
    }
    onChangeCage(e) {
        this.setState({
            cage: e.target.value,
        });
    }
    onChangecagenotes(e) {
        this.setState({
            cage_notes: e.target.value,
        });
    }
    onChangeGasket(e) {
        this.setState({
            gasket: e.target.value,
        });
    }
    onChangegasketnotes(e) {
        this.setState({
            gasket_notes: e.target.value,
        });
    }
    onChangeGlandePack(e) {
        this.setState({
            glande_packing: e.target.value,
        });
    }
    onChangeglandenotes(e) {
        this.setState({
            glande_packing_notes: e.target.value,
        });
    }
    onChangeSealRing(e) {
        this.setState({
            seal_ring: e.target.value,
        });
    }
    onChangeSeatRingNotes(e) {
        this.setState({
            seal_ring_notes: e.target.value,
        });
    }
    onChangeGlandeFastner(e) {
        this.setState({
            glande_fastners: e.target.value,
        });
    }
    onChangeGlandeFastnersNotes(e) {
        this.setState({
            glande_fastners_notes: e.target.value,
        });
    }
    onChangeBodyFastner(e) {
        this.setState({
            body_fastners: e.target.value,
        });
    }
    onChangebodyfastnotes(e) {
        this.setState({
            body_fastners_notes: e.target.value,
        });
    }
    onChangeother(e) {
        this.setState({
            other: e.target.value,
        });
    }
    valveRepairSubmit() {
            $("#root").addClass("disable_root");
            $("#loader").removeAttr("style").addClass("show_loader");
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const savejobData = {
                job_id: localStorage['job_id'],
                body: this.state.body,
                body_notes: this.state.body_notes,
                bonnet: this.state.bonnet,
                bonnet_notes: this.state.bonnet_notes,
                plug_disc_ball: this.state.plug_disc_ball,
                plug_disc_ball_select: this.state.plug_disc_ball_select,
                plug_disc_ball_notes: this.state.plug_disc_ball_notes,
                shaft_stem: this.state.shaft_stem,
                shaft_stem_select: this.state.shaft_stem_select,
                stem_notes: this.state.stem_notes,
                metal_ring_option: this.state.metal_ring_option,
                metal_ring: this.state.metal_ring,
                metal_ring_notes: this.state.metal_ring_notes,
                soft_ring: this.state.soft_ring,
                soft_ring_notes: this.state.soft_ring_notes,
                cage: this.state.cage,
                cage_notes: this.state.cage_notes,
                gasket: this.state.gasket,
                gasket_notes: this.state.gasket_notes,
                glande_packing: this.state.glande_packing,
                glande_packing_notes: this.state.glande_packing_notes,
                seal_ring: this.state.seal_ring,
                seal_ring_notes: this.state.seal_ring_notes,
                glande_fastners: this.state.glande_fastners,
                glande_fastners_notes: this.state.glande_fastners_notes,
                body_fastners: this.state.body_fastners,
                body_fastners_notes: this.state.body_fastners_notes,
                other: this.state.other,

                bellow: this.state.bellow,
                bellow_notes: this.state.bellow_notes,
                is_valve_applicable: this.state.is_valve_applicable,
            }
            axios.post(server_config.API_BASE_URL+"updateValveRepair", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    if(this.state.page_path == "Dashboard"){
                        localStorage['form_submit_alert'] = "Valve Repair Detail Updated Successfully";
                    }
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                    // alert("Valve Repair Detail Updated SuccessFully");
                    this.props.history.push("./"+this.state.page_path);
                }).catch(function (error) {
                    alert(error);
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                });
    }
    render() {
        return (
            <>
                <div className="header pt-5">
                    <Container fluid>
                        <div className="header-body">
                            <Card className="bg-secondary shadow border-0 mb-3">
                                <CardBody>
                                    <Row>
                                    <Col lg="12">
                                    <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                                        <select ref="select_module" defaultValue="Valve Repair" className="form-control form-control-alternative" onChange={this.handleModuleChange.bind(this, "select_module")}>
                                            <option value="Customer & Job">Customer & Job</option>
                                            <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                                            <option value="Open Inspection Image">Open Inspection Image</option>
                                            <option value="General Service">General Service</option>
                                            <option value="Valve Repair">Valve Repair</option>
                                            <option value="Actuator Repair">Actuator Repair</option>
                                            <option value="Repaired Images">Repaired Images</option>
                                            <option value="Accessories Detail">Accessories Detail</option>
                                            <option value="Valve Testing">Valve Testing</option>
                                            <option value="Dispatch">Dispatch</option>
                                        </select>
                                    </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                </div>
                {/* Page content */}
                <Container fluid id="form">
                    <Row>
                        <Col lg="12">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-3">
                                    <div>
                                        <label className="mb-0 job_title">Valve Repair</label>
                                        <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                                            <i className="ni ni-bold-left back_icon" />
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                        <Col lg="12">
                                            <FormGroup className="mb-2">
                                                <label className="form-control-label">Is Applicable?</label>
                                                <div className="row">
                                                    <div className="chk_col">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="is_valve_applicable1"
                                                                name="is_valve_applicable"
                                                                type="radio"
                                                                value="1"
                                                                checked={(this.state.is_valve_applicable === 1)}
                                                                onChange={this.onChangeValveapplicable}
                                                            />
                                                            <label className="custom-control-label" htmlFor="is_valve_applicable1">
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="chk_col">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="is_valve_applicable2"
                                                                name="is_valve_applicable"
                                                                type="radio"
                                                                value="2"
                                                                checked={(this.state.is_valve_applicable === 2)}
                                                                onChange={this.onChangeValveapplicable}
                                                            />
                                                            <label className="custom-control-label" htmlFor="is_valve_applicable2">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Body
                                                        </label>
                                                    <select className="form-control form-control-alternative" maxLength="50" ref="body" name="body" value={this.state.body} onChange={this.onChangeBody}>
                                                        <option value="">Select</option>
                                                        <option value="Gasket Face Serrations">Gasket Face Serrations</option>
                                                        <option value="Welding">Welding</option>
                                                        <option value="RethReading">RethReading</option>
                                                        <option value="Machining">Machining</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Welding & Machining">Welding & Machining</option>
                                                        <option value="Welding, RethReading & Machining">Welding, RethReading & Machining</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="body_notes" value={this.state.body_notes} type="text" onChange={this.onChangebodynotes} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Bonnet
                                                        </label>
                                                    <select className="form-control form-control-alternative" maxLength="50" ref="bonnet" name="bonnet" value={this.state.bonnet} onChange={this.onChangeBonnet}>
                                                        <option value="">Select</option>
                                                        <option value="Packing Bore Machining">Packing Bore Machining</option>
                                                        <option value="Gasket Face Serration">Gasket Face Serration</option>
                                                        <option value="Welding">Welding</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Welding & Machining">Welding & Machining</option>
                                                        <option value="Welding & Packing Bore Machining">Welding & Packing Bore Machining</option>
                                                        <option value="Welding & Gasket Face Serration">Welding & Gasket Face Serration</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="bonnet_notes" value={this.state.bonnet_notes} type="text" maxLength="100" onChange={this.onChangebonnetnotes} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <div className="row">
                                                        <div className="valve_rd_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="plug_disc_ball_rd1"
                                                                    name="plug_disc_ball"
                                                                    type="radio"
                                                                    value="PLUG"
                                                                    checked={(this.state.plug_disc_ball === "PLUG")}
                                                                    onChange={this.onChangeplug}
                                                                    maxLength="50"
                                                                />
                                                                <label className="custom-control-label" htmlFor="plug_disc_ball_rd1">
                                                                    PLUG
                                                                        </label>
                                                            </div>
                                                        </div>
                                                        <div className="valve_rd_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="plug_disc_ball_rd2"
                                                                    name="plug_disc_ball"
                                                                    type="radio"
                                                                    value="DISC"
                                                                    maxLength="50"
                                                                    checked={(this.state.plug_disc_ball === "DISC")}
                                                                    onChange={this.onChangeplug}
                                                                />
                                                                <label className="custom-control-label" htmlFor="plug_disc_ball_rd2">
                                                                    DISC
                                                                        </label>
                                                            </div>
                                                        </div>
                                                        <div className="valve_rd_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="plug_disc_ball_rd3"
                                                                    name="plug_disc_ball"
                                                                    type="radio"
                                                                    value="BALL"
                                                                    maxLength="50"
                                                                    checked={(this.state.plug_disc_ball === "BALL")}
                                                                    onChange={this.onChangeplug}
                                                                />
                                                                <label className="custom-control-label" htmlFor="plug_disc_ball_rd3">
                                                                    BALL
                                                                        </label>
                                                            </div>
                                                        </div>
                                                        <div className="valve_rd_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="plug_disc_ball_rd4"
                                                                    name="plug_disc_ball"
                                                                    type="radio"
                                                                    value="WEDGE"
                                                                    maxLength="50"
                                                                    checked={(this.state.plug_disc_ball === "WEDGE")}
                                                                    onChange={this.onChangeplug}
                                                                />
                                                                <label className="custom-control-label" htmlFor="plug_disc_ball_rd4">
                                                                    WEDGE
                                                                        </label>
                                                            </div>
                                                        </div>
                                                        <div className="valve_rd_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="plug_disc_ball_rd5"
                                                                    name="plug_disc_ball"
                                                                    type="radio"
                                                                    value="NOZZEL"
                                                                    maxLength="50"
                                                                    checked={(this.state.plug_disc_ball === "NOZZEL")}
                                                                    onChange={this.onChangeplug}
                                                                />
                                                                <label className="custom-control-label" htmlFor="plug_disc_ball_rd5">
                                                                    NOZZEL
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <select className="form-control form-control-alternative" maxLength="50" ref="plug_disc_ball_select" name="plug_disc_ball_select" value={this.state.plug_disc_ball_select} onChange={this.onChangePlugSelect}>
                                                        <option value="">Select</option>
                                                        <option value="Welding">Welding</option>
                                                        <option value="Machining">Machining</option>
                                                        <option value="Polishing">Polishing</option>
                                                        <option value="Grinding">Grinding</option>
                                                        <option value="Lapping">Lapping</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Welding & Machining">Welding & Machining</option>
                                                        <option value="Welding, Machining & Lapping">Welding, Machining & Lapping</option>
                                                        <option value="Machining & Lapping">Machining & Lapping</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="plug_disc_ball_notes" value={this.state.plug_disc_ball_notes} onChange={this.onChangeplug_notes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <div className="row">
                                                        <div className="col_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="shaft_stem_rd1"
                                                                    name="shaft_stem"
                                                                    type="radio"
                                                                    value="Stem"
                                                                    checked={(this.state.shaft_stem === "Stem")}
                                                                    onChange={this.onChangeshaft}
                                                                    maxLength="50"
                                                                />
                                                                <label className="custom-control-label" htmlFor="shaft_stem_rd1">
                                                                    Stem
                                                                        </label>
                                                            </div>
                                                        </div>
                                                        <div className="col_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="shaft_stem_rd2"
                                                                    name="shaft_stem"
                                                                    type="radio"
                                                                    value="Shaft"
                                                                    checked={(this.state.shaft_stem === "Shaft")}
                                                                    onChange={this.onChangeshaft}
                                                                    maxLength="50"
                                                                />
                                                                <label className="custom-control-label" htmlFor="shaft_stem_rd2">
                                                                    Shaft
                                                                        </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <select className="form-control form-control-alternative" maxLength="50" ref="shaft_stem_select" name="shaft_stem_select" value={this.state.shaft_stem_select} onChange={this.onChangeStem}>
                                                        <option value="">Select</option>
                                                        <option value="Polishing">Polishing</option>
                                                        <option value="Welding">Welding</option>
                                                        <option value="Machining">Machining</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Welding & Machining">Welding & Machining</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control form-control-alternative mt-1" maxLength="100" placeholder="Notes" name="stem_notes" name="stem_notes" value={this.state.stem_notes} onChange={this.onChangestem_note} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <div className="row">
                                                        <div className="col_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="metal_ring_option_rd2"
                                                                    name="metal_ring_option"
                                                                    type="radio"
                                                                    value="Metal Seat Ring"
                                                                    checked={(this.state.metal_ring_option === "Metal Seat Ring")}
                                                                    onChange={this.onChangeMetalOption}
                                                                    maxLength="50"
                                                                />
                                                                <label className="custom-control-label" htmlFor="metal_ring_option_rd2">
                                                                    Metal Seat Ring
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="metal_ring_option_rd3"
                                                                    name="metal_ring_option"
                                                                    type="radio"
                                                                    value="DISC"
                                                                    checked={(this.state.metal_ring_option === "DISC")}
                                                                    onChange={this.onChangeMetalOption}
                                                                    maxLength="50"
                                                                />
                                                                <label className="custom-control-label" htmlFor="metal_ring_option_rd3">
                                                                    DISC
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <select className="form-control form-control-alternative" maxLength="50" ref="metal_ring" name="metal_ring" value={this.state.metal_ring} onChange={this.onChangeMetal}>
                                                        <option value="">Select</option>
                                                        <option value="Machining & Lapping">Machining & Lapping</option>
                                                        <option value="Welding, Machining & Lapping">Welding, Machining & Lapping</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Polishing & Lapping">Polishing & Lapping</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="Machining">Machining</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="metal_ring_notes" value={this.state.metal_ring_notes} onChange={this.onChangemetal_note} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Soft Seat Ring
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="soft_ring" maxLength="50" name="soft_ring" value={this.state.soft_ring} onChange={this.onChangeSoftRing}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: PTFE">Replace MOC: PTFE</option>
                                                        <option value="Replace MOC: CFT">Replace MOC: CFT</option>
                                                        <option value="Replace MOC: GFT">Replace MOC: GFT</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="soft_ring_notes" maxLength="100" value={this.state.soft_ring_notes} onChange={this.onChangesoftnotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Cage
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="cage" name="cage" value={this.state.cage} maxLength="50" onChange={this.onChangeCage}>
                                                        <option value="">Select</option>
                                                        <option value="Welding">Welding</option>
                                                        <option value="Machining">Machining</option>
                                                        <option value="Polishing">Polishing</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Welding & Machining">Welding & Machining</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="cage_notes" maxLength="100" value={this.state.cage_notes} onChange={this.onChangecagenotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Bellow
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="bellow" name="bellow" value={this.state.bellow} maxLength="50" onChange={this.onChangeBellow}>
                                                        <option value="">Select</option>
                                                        <option value="N/A">N/A</option>
                                                        <option value="Replace - PTFE">Replace - PTFE</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace - Metal">Replace - Metal</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="bellow_notes" maxLength="100" value={this.state.bellow_notes} onChange={this.onChangebellownotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Gasket
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="gasket" name="gasket" maxLength="50" value={this.state.gasket} onChange={this.onChangeGasket}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: PTFE">Replace MOC: PTFE</option>
                                                        <option value="Replace MOC: Graphoil">Replace MOC: Graphoil</option>
                                                        <option value="Replace MOC: Spiral Wound">Replace MOC: Spiral Wound</option>
                                                        <option value="Replace MOC: Metal">Replace MOC: Metal</option>
                                                        <option value="Replace MOC non asbestos">Replace MOC non asbestos</option>
                                                        <option value="Replace MOC: CFT">Replace MOC: CFT</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="gasket_notes" maxLength="100" value={this.state.gasket_notes} onChange={this.onChangegasketnotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Glande Packing
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="glande_packing" name="glande_packing" maxLength="50" value={this.state.glande_packing} onChange={this.onChangeGlandePack}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: PTFE">Replace MOC: PTFE</option>
                                                        <option value="Replace MOC: Graphoil">Replace MOC: Graphoil</option>
                                                        <option value="Replace MOC: PTFE V-RING">Replace MOC: PTFE V-RING</option>
                                                        <option value="Replace MOC: Graphoil RING">Replace MOC: Graphoil RING</option>
                                                         <option value="Replace MOC: CFT">Replace MOC: CFT</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="glande_packing_notes" maxLength="100" value={this.state.glande_packing_notes} onChange={this.onChangeglandenotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Seal Ring
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="seal_ring" name="seal_ring" maxLength="50" value={this.state.seal_ring} onChange={this.onChangeSealRing}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: PTFE">Replace MOC: PTFE</option>
                                                        <option value="Replace MOC: Metal">Replace MOC: Metal</option>
                                                        <option value="Replace MOC: CFT">Replace MOC: CFT</option>
                                                        <option value="Replace MOC: GFT">Replace MOC: GFT</option>
                                                        <option value="Replace MOC: Graphoil">Replace MOC: Graphoil</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="seal_ring_notes" maxLength="100" value={this.state.seal_ring_notes} onChange={this.onChangeSeatRingNotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Glande Fastners
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="glande_fastners" name="glande_fastners" maxLength="50" value={this.state.glande_fastners} onChange={this.onChangeGlandeFastner}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: SS">Replace MOC: SS</option>
                                                        <option value="Replace MOC: High Tensile">Replace MOC: High Tensile</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="glande_fastners_notes" maxLength="100" value={this.state.glande_fastners_notes} onChange={this.onChangeGlandeFastnersNotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Body Fastners
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="body_fastners" maxLength="50" name="body_fastners" value={this.state.body_fastners} onChange={this.onChangeBodyFastner}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: B7 Studs & H2 NUTS">Replace MOC: B7 Studs & H2 NUTS</option>
                                                        <option value="Replace MOC: SS">Replace MOC: SS</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" name="body_fastners_notes" maxLength="100" value={this.state.body_fastners_notes} onChange={this.onChangebodyfastnotes} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Other</label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        rows="2"
                                                        type="textarea"
                                                        name="other"
                                                        value={this.state.other}
                                                        onChange={this.onChangeother}
                                                        maxLength="255"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" className="text-center">
                                                <FormGroup className="btn_formgrp">
                                                    <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                                                        <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                                                        <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                                                    </button>
                                                    <button type="button" className="btn btn-danger btn_save" onClick={this.valveRepairSubmit}>Save</button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                                                        <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                                                        <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                                                    </button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default valve_repair;
