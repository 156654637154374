import React from "react";

// reactstrap components
import {
    // Button,
    Card,
    CardHeader,
    //CardTitle,
    CardBody,
    FormGroup,
    //Form,
    Input,
    Container,
    Row,
    Col,
    InputGroupAddon,
    InputGroupText,
    InputGroup,Modal
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
import SignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-canvas';
class valve_testing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            testing_media: 1,
            is_completed: 0,
            errors: {},
            is_submit_click: 0,
            seat_leak_pressure: "",
            seat_leak_leckage: "",
            seat_leak_test: 1,
            body_leak_pressure: "",
            body_leak_test: 1,
            actuator_pressure: "",
            safety_set_pressure: "",
            safety_rest_pressure: "",
            actuator_test: 1,
            safety_valve_test: 1,
            positioner_calibration: 1,
            accessories_tested: 1,
            testing_notes: "",
            seat_leak_pressure_unit: "",
            seat_leak_leckage_unit: "",
            body_leak_pressure_unit: "",
            actuator_pressure_unit: "",
            safety_set_pressure_unit: "",
            safety_rest_pressure_unit: "",
            seat_leak_remark: "",
            body_leak_remark: "",
            actuator_remark: "",
            safety_valve_remark: "",

            technician_name : "",
            EnggtrimmedDataURL: null,
            SupertrimmedDataURL: null,
            is_engg_sign: 0,
            is_super_sign: 0,
            supervisor_name : "",
            engineer_name : "",
            page_path: "Dashboard",

            seat_leak_applicable: 1,
            body_leak_applicable: 1,
            actuator_applicable:1,
            safety_valve_applicable:1,
            // accessories_applicable: 1,
        };
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleIsCompletedChange = this.handleIsCompletedChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.valveTestSubmit = this.valveTestSubmit.bind(this);
        this.GoToDashboard = this.GoToDashboard.bind(this);
        this.handleModuleChange = this.handleModuleChange.bind(this);
        this.onChangeseat = this.onChangeseat.bind(this);
        this.onChangebody = this.onChangebody.bind(this);
        this.onChangeactuator = this.onChangeactuator.bind(this);
        this.onChangeSafetyValve = this.onChangeSafetyValve.bind(this);
        this.onChangepositioner = this.onChangepositioner.bind(this);
        this.onChangeacce = this.onChangeacce.bind(this);
        this.onChangenotes = this.onChangenotes.bind(this);
        this.onchangeSeatPressure = this.onchangeSeatPressure.bind(this);
        this.onchangeSeatLeackage = this.onchangeSeatLeackage.bind(this);
        this.onchangeBodyPressure = this.onchangeBodyPressure.bind(this);
        this.onchangeActuatorPressure = this.onchangeActuatorPressure.bind(this);
        this.onchangeSafetySetPressure = this.onchangeSafetySetPressure.bind(this);
        this.onchangeSafetyRestPressure = this.onchangeSafetyRestPressure.bind(this);
        this.onchangeSeatLeakRmk = this.onchangeSeatLeakRmk.bind(this);
        this.onchangeBodyLeakRmk = this.onchangeBodyLeakRmk.bind(this);
        this.onchangeActuatorRmk = this.onchangeActuatorRmk.bind(this);
        this.onchangeSafetyValveRmk = this.onchangeSafetyValveRmk.bind(this);
        this.onChangeSeatPresuureUnit = this.onChangeSeatPresuureUnit.bind(this);
        this.onChangeSeatLeakUnit = this.onChangeSeatLeakUnit.bind(this);
        this.onchangeBodyPressureUnit = this.onchangeBodyPressureUnit.bind(this);
        this.onchangeActuatorPressureUnit = this.onchangeActuatorPressureUnit.bind(this);
        this.onchangeSafetySetPressureUnit = this.onchangeSafetySetPressureUnit.bind(this);
        this.onchangeSafetyRestPressureUnit = this.onchangeSafetyRestPressureUnit.bind(this);

        this.superclear = this.superclear.bind(this);
        this.enggclear = this.enggclear.bind(this);
        this.saveEnggsign = this.saveEnggsign.bind(this);
        this.saveSupersign = this.saveSupersign.bind(this);
        this.Supmodal = this.Supmodal.bind(this);
        this.closeSupmodal = this.closeSupmodal.bind(this);
        this.enggmodal = this.enggmodal.bind(this);
        this.closeengmodal = this.closeengmodal.bind(this);
        this.onChangesuptxt = this.onChangesuptxt.bind(this);
        this.onChangeengtxt = this.onChangeengtxt.bind(this);
        this.onChangetechtxt = this.onChangetechtxt.bind(this);

        this.PreviousClick = this.PreviousClick.bind(this);
        this.NextClick = this.NextClick.bind(this);
        this.NextSaveClick = this.NextSaveClick.bind(this);
        this.PrevSaveClick = this.PrevSaveClick.bind(this);

        this.onChangeseatapplicable = this.onChangeseatapplicable.bind(this);
        this.onChangebodyapplicable = this.onChangebodyapplicable.bind(this);
        this.onChangeactuatorapplicable = this.onChangeactuatorapplicable.bind(this);
        this.onChangeSafetyValveApplicable = this.onChangeSafetyValveApplicable.bind(this);
    }
    GoToDashboard() {
        this.props.history.push('./Dashboard');
    }
    PreviousClick(){
        this.props.history.push('./accessories_detail');
    }
    NextClick(){
        this.props.history.push('./dispatch');
    }
    NextSaveClick(){
        this.setState({
          page_path: "dispatch",
        });
        this.valveTestSubmit();
      }
      PrevSaveClick(){
        this.setState({
          page_path: "accessories_detail",
        });
        this.valveTestSubmit();
      }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const jobData = {
                job_id: localStorage['job_id'],
                module_name: "job_valve_testing"
            }
            axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    this.setState({
                        testing_media: result.data.job_detail.testing_media,
                        is_completed: result.data.job_detail.is_completed,
                        seat_leak_pressure: result.data.job_detail.seat_leak_pressure,
                        seat_leak_leckage: result.data.job_detail.seat_leak_leckage,
                        seat_leak_test: result.data.job_detail.seat_leak_test,
                        body_leak_pressure: result.data.job_detail.body_leak_pressure,
                        body_leak_test: result.data.job_detail.body_leak_test,
                        actuator_pressure: result.data.job_detail.actuator_pressure,
                        safety_set_pressure: result.data.job_detail.safety_set_pressure,
                        safety_rest_pressure: result.data.job_detail.safety_rest_pressure,
                        actuator_test: result.data.job_detail.actuator_test,
                        safety_valve_test: result.data.job_detail.safety_valve_test,
                        positioner_calibration: result.data.job_detail.positioner_calibration,
                        accessories_tested: result.data.job_detail.accessories_tested,
                        testing_notes: result.data.job_detail.testing_notes,
                        seat_leak_pressure_unit: result.data.job_detail.seat_leak_pressure_unit,
                        seat_leak_leckage_unit: result.data.job_detail.seat_leak_leckage_unit,
                        body_leak_pressure_unit: result.data.job_detail.body_leak_pressure_unit,
                        actuator_pressure_unit: result.data.job_detail.actuator_pressure_unit,
                        safety_set_pressure_unit: result.data.job_detail.safety_set_pressure_unit,
                        safety_rest_pressure_unit: result.data.job_detail.safety_rest_pressure_unit,

                        seat_leak_remark: result.data.job_detail.seat_leak_remark,
                        body_leak_remark: result.data.job_detail.body_leak_remark,
                        actuator_remark: result.data.job_detail.actuator_remark,
                        safety_valve_remark: result.data.job_detail.safety_valve_remark,

                        technician_name: result.data.job_detail.technician_name,
                        EnggtrimmedDataURL: (result.data.job_detail.engineer_sign != '' && result.data.job_detail.engineer_sign != null) ? result.data.base_url + "/" + result.data.job_detail.engineer_sign : null,
                        SupertrimmedDataURL: (result.data.job_detail.supervisor_sign != '' && result.data.job_detail.supervisor_sign != null) ? result.data.base_url + "/" + result.data.job_detail.supervisor_sign : null,
                        supervisor_name: result.data.job_detail.supervisor_name,
                        engineer_name: result.data.job_detail.engineer_name,

                        seat_leak_applicable: result.data.job_detail.seat_leak_applicable,
                        body_leak_applicable: result.data.job_detail.body_leak_applicable,
                        actuator_applicable: result.data.job_detail.actuator_applicable,
                        safety_valve_applicable: result.data.job_detail.safety_valve_applicable,
                        // accessories_applicable: result.data.job_detail.accessories_applicable,

                    });
                }).catch(function (error) {
                    //console.log(error);
                });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate () {
        //CODE TO SET INPUTGROUP TEXT BY SELECTING NOT APPLICABLE RADIO BUTTON
        if (document.querySelector('input[name=seat_leak_applicable]:checked').value == 2) {
            // console.log('2 value');
            // $("input[name$='seat_leak_pressure']").val('0');
            // $("input[name$='seat_leak_leckage']").val('0');
            // $("input[name$='seat_leak_remark']").val('-');

            $("input[name$='seat_leak_pressure']").attr('readonly','true');
            $("input[name$='seat_leak_leckage']").attr('readonly','true');
            $("#seat_leak_pressure_unit").attr('disabled','disabled');
            $("#seat_leak_leckage_unit").attr('disabled','disabled');

            $("input[name$='seat_leak_pressure']").val('');
            $("input[name$='seat_leak_leckage']").val('');
            $("#seat_leak_pressure_unit").val('');
            $("#seat_leak_leckage_unit").val('');
        }else{
            $("input[name$='seat_leak_pressure']").removeAttr('readonly');
            $("input[name$='seat_leak_leckage']").removeAttr('readonly');
            $("#seat_leak_pressure_unit").removeAttr('disabled');
            $("#seat_leak_leckage_unit").removeAttr('disabled');
        }

        if (document.querySelector('input[name=body_leak_applicable]:checked').value == 2) {
            $("input[name$='body_leak_pressure']").attr('readonly','true');
            $("#body_leak_pressure_unit").attr('disabled','disabled');
            $("input[name$='body_leak_pressure']").val('');
            $("#body_leak_pressure_unit").val('');
            
        }else{
            $("input[name$='body_leak_pressure']").removeAttr('readonly');
            $("#body_leak_pressure_unit").removeAttr('disabled');
        }
        
        if (document.querySelector('input[name=actuator_applicable]:checked').value == 2) {
            $("input[name$='actuator_pressure']").attr('readonly','true');
            $("#actuator_pressure_unit").attr('disabled','disabled');
            $("input[name$='actuator_pressure']").val('');
            $("#actuator_pressure_unit").val('');
        }else{
            $("input[name$='actuator_pressure']").removeAttr('readonly');
            $("#actuator_pressure_unit").removeAttr('disabled');
        }

        if (document.querySelector('input[name=safety_valve_applicable]:checked').value == 2) {
            $("input[name$='safety_set_pressure']").attr('readonly','true').val('');
            $("#safety_set_pressure_unit").attr('disabled','disabled').val('');
            $("input[name$='safety_rest_pressure']").attr('readonly','true').val('');
            $("#safety_rest_pressure_unit").attr('disabled','disabled').val('');
        }else{
            $("input[name$='safety_set_pressure']").removeAttr('readonly');
            $("#safety_set_pressure_unit").removeAttr('disabled');
            $("input[name$='safety_rest_pressure']").removeAttr('readonly');
            $("#safety_rest_pressure_unit").removeAttr('disabled');
        }
    }
    //select module
    handleModuleChange(e) {
        if (this.refs.select_module.value === 'Customer & Job') {
            this.props.history.push('./Customer');
        }
        else if (this.refs.select_module.value === 'Problems & Open Inspection') {
            this.props.history.push('./open_inspection');
        }
        else if (this.refs.select_module.value === 'Open Inspection Image') {
            this.props.history.push('./open_inspection_image');
        }
        else if (this.refs.select_module.value === 'General Service') {
            this.props.history.push('./general_services');
        }
        else if (this.refs.select_module.value === 'Valve Repair') {
            this.props.history.push('./valve_repair');
        }
        else if (this.refs.select_module.value === 'Actuator Repair') {
            this.props.history.push('./actuator_repair');
        }
        else if (this.refs.select_module.value === 'Accessories Detail') {
            this.props.history.push('./accessories_detail');
        }
        else if (this.refs.select_module.value === 'Valve Testing') {
            this.props.history.push('./valve_testing');
        }
        else if (this.refs.select_module.value === 'Repaired Images') {
            this.props.history.push('./repaired_image');
        }
        else if (this.refs.select_module.value === 'Dispatch') {
            this.props.history.push('./dispatch');
        }
    }
    handleMediaChange(e) {
        this.setState({
            testing_media: parseInt(e.target.value)
        });
    }
    handleIsCompletedChange(e) {
        this.setState({
            is_completed: parseInt(e.target.value)
        });
    }
    handleValidation(e) {
        let errors = {};
        let formIsValid = true;

        //Name
        if(this.state.seat_leak_applicable == 1){
            if (!this.state.seat_leak_pressure) {
                formIsValid = false;
                errors["seat_leak_pressure"] = "Please Enter Test Pressure";
            }
        
            if (!this.state.seat_leak_leckage) {
                formIsValid = false;
                errors["seat_leak_leckage"] = "Please Enter Leakage Reading";
            }
        }
        if(this.state.body_leak_applicable == 1){
            if (!this.state.body_leak_pressure) {
                formIsValid = false;
                errors["body_leak_pressure"] = "Please Enter Test Pressure";
            }
        }
        if(this.state.actuator_applicable == 1){
            if (!this.state.actuator_pressure) {
                formIsValid = false;
                errors["actuator_pressure"] = "Please Enter Test Pressure";
            }
        }
        if(this.state.safety_valve_applicable == 1){
            if (!this.state.safety_set_pressure) {
                formIsValid = false;
                errors["safety_set_pressure"] = "Please Enter Set Pressure";
            }
            if (!this.state.safety_rest_pressure) {
                formIsValid = false;
                errors["safety_rest_pressure"] = "Please Enter Reset Pressure";
            }
        }
        if (!this.state.supervisor_name) {
            formIsValid = false;
            errors["supervisor_name"] = "Please Enter Supervisor Name.";
        }
        if (!this.state.engineer_name) {
            formIsValid = false;
            errors["engineer_name"] = "Please Enter Engineer Name.";
        }
        this.setState({ errors: errors });
        if (e == 1) {
            return formIsValid;
        }
    }
    inputValidation(e) {
        if (e === 1) {
            this.setState({
                supervisor_name: this.state.supervisor_name,
            }, () => { this.handleValidation(0) });
        }
    }
    Supmodal() { //open tech sign Modal
        this.setState({
            supmodal: true
        });
    }
    closeSupmodal() {
        this.setState({
            supmodal: false
        });
    }
    superclear() {
        this.supPad.clear();
        this.setState({
            SupertrimmedDataURL: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=',
            is_super_sign: 0
        });
    }
    enggmodal() { //open tech sign Modal
        this.setState({
            engmodal: true
        });
    }
    closeengmodal() {
        this.setState({
            engmodal: false
        });
    }
    enggclear() {
        this.enggPad.clear();
        this.setState({
            EnggtrimmedDataURL: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=',
            is_engg_sign: 0
        });
    }
    
    saveSupersign() {
        this.setState({
            SupertrimmedDataURL: this.supPad.getTrimmedCanvas().toDataURL('image/png'),
            is_super_sign: 1,
            supmodal: false,
        });
    }
    
    saveEnggsign() {
        this.setState({
            EnggtrimmedDataURL: this.enggPad.getTrimmedCanvas().toDataURL('image/png'),
            is_engg_sign: 1,
            engmodal: false,
        });
    }

    onChangesuptxt(e) {
        this.setState({
            supervisor_name: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onChangeengtxt(e) {
        this.setState({
            engineer_name: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }


    onChangeseat(e) {
        this.setState({
            seat_leak_test: parseInt(e.target.value),
        });
    }
    onChangeseatapplicable(e) {
        this.setState({
            seat_leak_applicable: parseInt(e.target.value),
        });
        // if(this.state.seat_leak_applicable == 1){
        //     this.state.is_seat_test=1
        // }else if(this.state.seat_leak_applicable == 2){
        //     this.state.is_seat_test=0
        // }
        // console.log('seat no :' +this.state.is_seat_test);
    }
    onChangebodyapplicable(e) {
        this.setState({
            body_leak_applicable: parseInt(e.target.value),
        });
        // if(this.state.body_leak_applicable == 1){
        //     this.state.is_body_test=1
        // }else if(this.state.body_leak_applicable == 2){
        //     this.state.is_body_test=0
        // }
        // console.log('body no :' +this.state.is_body_test);
    }
    onChangeactuatorapplicable(e) {
        this.setState({
            actuator_applicable: parseInt(e.target.value),
        });
    }
    onChangeSafetyValveApplicable(e) {
        this.setState({
            safety_valve_applicable: parseInt(e.target.value),
        });
    }
    // onChangeisapplicable(e) {
    //     this.setState({
    //         accessories_applicable: parseInt(e.target.value),
    //     });
    // }
    onChangebody(e) {
        this.setState({
            body_leak_test: parseInt(e.target.value),
        });
    }
    onChangeactuator(e) {
        this.setState({
            actuator_test: parseInt(e.target.value),
        });
    }
    onChangeSafetyValve(e) {
        this.setState({
            safety_valve_test: parseInt(e.target.value),
        });
    }
    onChangepositioner(e) {
        this.setState({
            positioner_calibration: parseInt(e.target.value),
        });
    }
    onChangeacce(e) {
        this.setState({
            accessories_tested: parseInt(e.target.value),
        });
    }
    onChangenotes(e) {
        this.setState({
            testing_notes: e.target.value,
        });
    }
    onchangeSeatPressure(e) {
        this.setState({
            seat_leak_pressure: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onchangeSeatLeackage(e) {
        this.setState({
            seat_leak_leckage: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onchangeBodyPressure(e) {
        this.setState({
            body_leak_pressure: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onchangeActuatorPressure(e) {
        this.setState({
            actuator_pressure: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onchangeSafetySetPressure(e) {
        this.setState({
            safety_set_pressure: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onchangeSafetyRestPressure(e) {
        this.setState({
            safety_rest_pressure: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onchangeSeatLeakRmk(e) {
        this.setState({
            seat_leak_remark: e.target.value,
        });
    }
    onchangeBodyLeakRmk(e) {
        this.setState({
            body_leak_remark: e.target.value,
        });
    }
    onchangeActuatorRmk(e) {
        this.setState({
            actuator_remark: e.target.value,
        });
    } 
    onchangeSafetyValveRmk(e) {
        this.setState({
            safety_valve_remark: e.target.value,
        });
    }
    onChangeSeatPresuureUnit(e) {
        this.setState({
            seat_leak_pressure_unit: e.target.value,
        });
    }
    onChangeSeatLeakUnit(e) {
        this.setState({
            seat_leak_leckage_unit: e.target.value,
        });
    }
    onchangeBodyPressureUnit(e) {
        this.setState({
            body_leak_pressure_unit: e.target.value,
        });
    }
    onchangeActuatorPressureUnit(e) {
        this.setState({
            actuator_pressure_unit: e.target.value,
        });
    }
    onchangeSafetySetPressureUnit(e) {
        this.setState({
            safety_set_pressure_unit: e.target.value,
        });
    }
    onchangeSafetyRestPressureUnit(e) {
        this.setState({
            safety_rest_pressure_unit: e.target.value,
        });
    }
    onChangetechtxt(e) {
        this.setState({
            technician_name: e.target.value,
        });
    }
    valveTestSubmit() {
        // e.preventDefault();
        this.setState({
            is_submit_click: 1
        });
        if (this.handleValidation(1)) {
            $("#root").addClass("disable_root");
            $("#loader").removeAttr("style").addClass("show_loader");
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const savejobData = {
                job_id: localStorage['job_id'],
                testing_media: this.state.testing_media,
                is_completed: this.state.is_completed,
                seat_leak_pressure: this.state.seat_leak_pressure,
                seat_leak_leckage: this.state.seat_leak_leckage,
                seat_leak_test: this.state.seat_leak_test,
                body_leak_pressure: this.state.body_leak_pressure,
                body_leak_test: this.state.body_leak_test,
                actuator_pressure: this.state.actuator_pressure,
                safety_set_pressure: this.state.safety_set_pressure,
                safety_rest_pressure: this.state.safety_rest_pressure,
                actuator_test: this.state.actuator_test,
                safety_valve_test: this.state.safety_valve_test,
                positioner_calibration: this.state.positioner_calibration,
                accessories_tested: this.state.accessories_tested,
                testing_notes: this.state.testing_notes,
                seat_leak_pressure_unit: this.state.seat_leak_pressure_unit,
                seat_leak_leckage_unit: this.state.seat_leak_leckage_unit,
                body_leak_pressure_unit: this.state.body_leak_pressure_unit,
                actuator_pressure_unit: this.state.actuator_pressure_unit,
                safety_set_pressure_unit: this.state.safety_set_pressure_unit,
                safety_rest_pressure_unit: this.state.safety_rest_pressure_unit,

                seat_leak_remark: this.state.seat_leak_remark,
                body_leak_remark: this.state.body_leak_remark,
                actuator_remark: this.state.actuator_remark,
                safety_valve_remark: this.state.safety_valve_remark,

                engineer_sign: this.state.EnggtrimmedDataURL,
                supervisor_sign: this.state.SupertrimmedDataURL,
                is_engg_sign: this.state.is_engg_sign,
                is_super_sign: this.state.is_super_sign,
                supervisor_name: this.state.supervisor_name,
                engineer_name: this.state.engineer_name,
                technician_name: this.state.technician_name,

                seat_leak_applicable: this.state.seat_leak_applicable,
                body_leak_applicable: this.state.body_leak_applicable,
                actuator_applicable: this.state.actuator_applicable,
                safety_valve_applicable: this.state.safety_valve_applicable,
                // accessories_applicable: this.state.accessories_applicable,
            }
            axios.post(server_config.API_BASE_URL+"updateValveTesting", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    if(this.state.page_path == "Dashboard"){
                        localStorage['form_submit_alert'] = "Valve Testing Detail Updated Successfully";
                    }
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                    // alert("Valve Testing Detail Updated SuccessFully");
                    this.props.history.push("./"+this.state.page_path);
                }).catch(function (error) {
                    alert(error);
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                });
        } else {
            alert("Please fill up all mandatory fields.");
            window.scrollTo(0, 0);
        }
    }
    render() {
        let { SupertrimmedDataURL, EnggtrimmedDataURL } = this.state
        return (
            <>
                <div className="header pt-5">
                    <Container fluid>
                        <div className="header-body">
                            <Card className="bg-secondary shadow border-0 mb-3">
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                        <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                                            <select ref="select_module" defaultValue="Valve Testing" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                                                <option value="Customer & Job">Customer & Job</option>
                                                <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                                                <option value="Open Inspection Image">Open Inspection Image</option>
                                                <option value="General Service">General Service</option>
                                                <option value="Valve Repair">Valve Repair</option>
                                                <option value="Actuator Repair">Actuator Repair</option>
                                                <option value="Repaired Images">Repaired Images</option>
                                                <option value="Accessories Detail">Accessories Detail</option>
                                                <option value="Valve Testing">Valve Testing</option>
                                                <option value="Dispatch">Dispatch</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                </div>
                {/* Page content */}
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-3">
                                    <div>
                                        <label className="mb-0 job_title">Valve Testing</label>
                                        <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                                            <i className="ni ni-bold-left back_icon" />
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup className="mb-2 row">
                                                    <label className="form-control-label col_media">Media</label>
                                                    <div className="col_div_media">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="testing_media1"
                                                                name="testing_media"
                                                                type="radio"
                                                                value="1"
                                                                checked={(this.state.testing_media === 1)}
                                                                onChange={this.handleMediaChange}
                                                            />
                                                            <label className="custom-control-label" htmlFor="testing_media1">
                                                                Air
                                                                </label>
                                                        </div>
                                                    </div>
                                                    <div className="col_div_media">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="testing_media2"
                                                                name="testing_media"
                                                                type="radio"
                                                                value="2"
                                                                checked={(this.state.testing_media === 2)}
                                                                onChange={this.handleMediaChange}
                                                            />
                                                            <label className="custom-control-label" htmlFor="testing_media2">
                                                                Hydro
                                                                </label>
                                                        </div>
                                                    </div>
                                                    <div className="col_div_media">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="testing_media3"
                                                                name="testing_media"
                                                                type="radio"
                                                                value="3"
                                                                checked={(this.state.testing_media === 3)}
                                                                onChange={this.handleMediaChange}
                                                            />
                                                            <label className="custom-control-label" htmlFor="testing_media3">
                                                                    Nitrogen
                                                                </label>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6">
                                                <FormGroup className="mb-2 row">
                                                    <label className="form-control-label col_media">Completed?</label>
                                                    <div className="col_div_media">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="is_completed1"
                                                                name="is_completed"
                                                                type="radio"
                                                                value="1"
                                                                checked={(this.state.is_completed === 1)}
                                                                onChange={this.handleIsCompletedChange}
                                                            />
                                                            <label className="custom-control-label" htmlFor="is_completed1">
                                                                Yes
                                                                </label>
                                                        </div>
                                                    </div>
                                                    <div className="col_div_media">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="is_completed2"
                                                                name="is_completed"
                                                                type="radio"
                                                                value="0"
                                                                checked={(this.state.is_completed === 0)}
                                                                onChange={this.handleIsCompletedChange}
                                                            />
                                                            <label className="custom-control-label" htmlFor="is_completed2">
                                                                No
                                                                </label>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
       
                                            <Col lg="12">
                                                <Card className="shadow mb-2">
                                                    <div className="card-body stock_card_body">
                                                        <div className="stock_card_data h3 font-weight-bold mb-0">Seat Leak / Passing Test
                                                        </div>
                                                        <hr className="stock_card_divider"></hr>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Is Applicable?</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="seat_leak_applicable1"
                                                                            name="seat_leak_applicable"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.seat_leak_applicable === 1)}
                                                                            onChange={this.onChangeseatapplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="seat_leak_applicable1">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="seat_leak_applicable2"
                                                                            name="seat_leak_applicable"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.seat_leak_applicable === 2)}
                                                                            onChange={this.onChangeseatapplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="seat_leak_applicable2">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Test Pressure<span className="text-danger"> *</span></label>
                                                            <InputGroup className="input-group-alternative">
                                                                <Input className="form-control-alternative" type="text" ref="seat_leak_pressure" id="seat_leak_pressure" name="seat_leak_pressure" value={this.state.seat_leak_pressure} onChange={this.onchangeSeatPressure} />
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText className="select_input p-0">
                                                                        <select ref="seat_leak_pressure_unit" id="seat_leak_pressure_unit" maxLength="10" name="seat_leak_pressure_unit" className="form-control form-control-alternative test_select" value={this.state.seat_leak_pressure_unit} onChange={this.onChangeSeatPresuureUnit}>
                                                                            <option value="">Select</option>
                                                                            <option value="kg/cm&sup2;">kg/cm&sup2;</option>
                                                                            <option value="PSI">PSI</option>
                                                                            <option value="BAR">BAR</option>
                                                                            <option value="MMWc">MMWc</option>
                                                                        </select>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                            <span className="error_cls">{this.state.errors["seat_leak_pressure"]}</span>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Leakage Reading<span className="text-danger"> *</span></label>
                                                            <InputGroup className="input-group-alternative">
                                                            <Input className="form-control-alternative" type="text" ref="seat_leak_leckage" name="seat_leak_leckage" value={this.state.seat_leak_leckage} onChange={this.onchangeSeatLeackage} />
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText className="select_input p-0">
                                                                        <select ref="seat_leak_leckage_unit" id="seat_leak_leckage_unit" maxLength="10" name="seat_leak_leckage_unit" className="form-control form-control-alternative test_select" value={this.state.seat_leak_leckage_unit} onChange={this.onChangeSeatLeakUnit}>
                                                                            <option value="">Select</option>
                                                                            <option value="ml/min">ml/min</option>
                                                                            <option value="SCFH">SCFH</option>
                                                                            <option value="Bubble/min">Bubble/min</option>
                                                                        </select>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                            <span className="error_cls">{this.state.errors["seat_leak_leckage"]}</span>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Test</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="seat_leak_test1"
                                                                            name="seat_leak_test"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.seat_leak_test === 1)}
                                                                            onChange={this.onChangeseat}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="seat_leak_test1">
                                                                            Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="seat_leak_test2"
                                                                            name="seat_leak_test"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.seat_leak_test === 2)}
                                                                            onChange={this.onChangeseat}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="seat_leak_test2">
                                                                            Not Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <Col lg="10">
                                                                    <Input className="form-control-alternative" type="text" maxLength="100" name="seat_leak_remark" placeholder="Notes" value={this.state.seat_leak_remark} onChange={this.onchangeSeatLeakRmk} />
                                                                </Col>
                                                            </div>
                                                        </FormGroup>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col lg="12">
                                                <Card className="shadow mb-3">
                                                    <div className="card-body stock_card_body">
                                                        <div className="stock_card_data h3 font-weight-bold mb-0">Body Leak Test
                                                        </div>
                                                        <hr className="stock_card_divider"></hr>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Is Applicable?</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="body_leak_applicable1"
                                                                            name="body_leak_applicable"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.body_leak_applicable === 1)}
                                                                            onChange={this.onChangebodyapplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="body_leak_applicable1">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="body_leak_applicable2"
                                                                            name="body_leak_applicable"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.body_leak_applicable === 2)}
                                                                            onChange={this.onChangebodyapplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="body_leak_applicable2">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Test Pressure<span className="text-danger"> *</span></label>
                                                            <InputGroup className="input-group-alternative">
                                                                <Input className="form-control-alternative" type="text" name="body_leak_pressure" ref="body_leak_pressure" value={this.state.body_leak_pressure} onChange={this.onchangeBodyPressure} />
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText className="select_input p-0">
                                                                        <select ref="body_leak_pressure_unit" id="body_leak_pressure_unit" maxLength="10" name="body_leak_pressure_unit" className="form-control form-control-alternative test_select" value={this.state.body_leak_pressure_unit} onChange={this.onchangeBodyPressureUnit}>
                                                                            <option value="">Select</option>
                                                                            <option value="kg/cm&sup2;">kg/cm&sup2;</option>
                                                                            <option value="PSI">PSI</option>
                                                                            <option value="BAR">BAR</option>
                                                                            <option value="MMWc">MMWc</option>
                                                                        </select>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                            <span className="error_cls">{this.state.errors["body_leak_pressure"]}</span>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Bonnet/Body/Gland Leakage</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="body_leak_test1"
                                                                            name="body_leak_test"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.body_leak_test === 1)}
                                                                            onChange={this.onChangebody}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="body_leak_test1">
                                                                            Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="body_leak_test2"
                                                                            name="body_leak_test"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.body_leak_test === 2)}
                                                                            onChange={this.onChangebody}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="body_leak_test2">
                                                                            Not Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <Col lg="10">
                                                                    <Input className="form-control-alternative" maxLength="100" type="text" name="body_leak_remark" placeholder="Notes" value={this.state.body_leak_remark} onChange={this.onchangeBodyLeakRmk} />
                                                                </Col>
                                                            </div>
                                                        </FormGroup>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col lg="12">
                                                <Card className="shadow mb-3">
                                                    <div className="card-body stock_card_body">
                                                        <div className="stock_card_data h3 font-weight-bold mb-0">Actuator Test
                                                        </div>
                                                        <hr className="stock_card_divider"></hr>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Is Applicable?</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="actuator_applicable1"
                                                                            name="actuator_applicable"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.actuator_applicable === 1)}
                                                                            onChange={this.onChangeactuatorapplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="actuator_applicable1">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="actuator_applicable2"
                                                                            name="actuator_applicable"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.actuator_applicable === 2)}
                                                                            onChange={this.onChangeactuatorapplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="actuator_applicable2">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Test Pressure<span className="text-danger"> *</span></label>
                                                            <InputGroup className="input-group-alternative">
                                                                <Input className="form-control-alternative" type="text" ref="actuator_pressure" name="actuator_pressure" value={this.state.actuator_pressure} onChange={this.onchangeActuatorPressure} />
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText className="select_input p-0">
                                                                        <select ref="actuator_pressure_unit" id="actuator_pressure_unit" maxLength="10" name="actuator_pressure_unit" className="form-control form-control-alternative test_select" value={this.state.actuator_pressure_unit} onChange={this.onchangeActuatorPressureUnit}>
                                                                            <option value="">Select</option>
                                                                            <option value="kg/cm&sup2;">kg/cm&sup2;</option>
                                                                            <option value="PSI">PSI</option>
                                                                            <option value="BAR">BAR</option>
                                                                            <option value="MMWc">MMWc</option>
                                                                        </select>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                            <span className="error_cls">{this.state.errors["actuator_pressure"]}</span>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Actuator Test Result</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="actuator_test1"
                                                                            name="actuator_test"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.actuator_test === 1)}
                                                                            onChange={this.onChangeactuator}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="actuator_test1">
                                                                            Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="actuator_test2"
                                                                            name="actuator_test"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.actuator_test === 2)}
                                                                            onChange={this.onChangeactuator}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="actuator_test2">
                                                                            Not Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <Col lg="10">
                                                                    <Input className="form-control-alternative" maxLength="100" type="text" name="actuator_remark" placeholder="Notes" value={this.state.actuator_remark} onChange={this.onchangeActuatorRmk} />
                                                                </Col>
                                                            </div>
                                                        </FormGroup>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col lg="12">
                                                <Card className="shadow mb-3">
                                                    <div className="card-body stock_card_body">
                                                        <div className="stock_card_data h3 font-weight-bold mb-0">Safety Valve Test
                                                        </div>
                                                        <hr className="stock_card_divider"></hr>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Is Applicable?</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="safety_applicable1"
                                                                            name="safety_valve_applicable"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.safety_valve_applicable === 1)}
                                                                            onChange={this.onChangeSafetyValveApplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="safety_applicable1">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="safety_valve_applicable2"
                                                                            name="safety_valve_applicable"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.safety_valve_applicable === 2)}
                                                                            onChange={this.onChangeSafetyValveApplicable}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="safety_valve_applicable2">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Set Pressure<span className="text-danger"> *</span></label>
                                                            <InputGroup className="input-group-alternative">
                                                                <Input className="form-control-alternative" type="text" ref="safety_set_pressure" name="safety_set_pressure" value={this.state.safety_set_pressure} onChange={this.onchangeSafetySetPressure} />
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText className="select_input p-0">
                                                                        <select ref="safety_set_pressure_unit" id="safety_set_pressure_unit" maxLength="10" name="safety_set_pressure_unit" className="form-control form-control-alternative test_select" value={this.state.safety_set_pressure_unit} onChange={this.onchangeSafetySetPressureUnit}>
                                                                            <option value="">Select</option>
                                                                            <option value="kg/cm&sup2;">kg/cm&sup2;</option>
                                                                            <option value="PSI">PSI</option>
                                                                            <option value="BAR">BAR</option>
                                                                            <option value="MMWc">MMWc</option>
                                                                        </select>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                            <span className="error_cls">{this.state.errors["safety_set_pressure"]}</span>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Reset Pressure<span className="text-danger"> *</span></label>
                                                            <InputGroup className="input-group-alternative">
                                                                <Input className="form-control-alternative" type="text" ref="safety_rest_pressure" name="safety_rest_pressure" value={this.state.safety_rest_pressure} onChange={this.onchangeSafetyRestPressure} />
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText className="select_input p-0">
                                                                        <select ref="safety_rest_pressure_unit" id="safety_rest_pressure_unit" maxLength="10" name="safety_rest_pressure_unit" className="form-control form-control-alternative test_select" value={this.state.safety_rest_pressure_unit} onChange={this.onchangeSafetyRestPressureUnit}>
                                                                            <option value="">Select</option>
                                                                            <option value="kg/cm&sup2;">kg/cm&sup2;</option>
                                                                            <option value="PSI">PSI</option>
                                                                            <option value="BAR">BAR</option>
                                                                            <option value="MMWc">MMWc</option>
                                                                        </select>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                            <span className="error_cls">{this.state.errors["safety_rest_pressure"]}</span>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Safety Valve Test Result</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="safety_valve_test1"
                                                                            name="safety_valve_test"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.safety_valve_test === 1)}
                                                                            onChange={this.onChangeSafetyValve}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="safety_valve_test1">
                                                                            Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="safety_valve_test2"
                                                                            name="safety_valve_test"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.safety_valve_test === 2)}
                                                                            onChange={this.onChangeSafetyValve}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="safety_valve_test2">
                                                                            Not Ok
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <Col lg="10">
                                                                    <Input className="form-control-alternative" maxLength="100" type="text" name="safety_valve_remark" placeholder="Notes" value={this.state.safety_valve_remark} onChange={this.onchangeSafetyValveRmk} />
                                                                </Col>
                                                            </div>
                                                        </FormGroup>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col lg="12">
                                                <Card className="shadow mb-3">
                                                    <div className="card-body stock_card_body">
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Positioner Calibration</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="positioner_calibration1"
                                                                            name="positioner_calibration"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.positioner_calibration === 1)}
                                                                            onChange={this.onChangepositioner}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="positioner_calibration1">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="positioner_calibration2"
                                                                            name="positioner_calibration"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.positioner_calibration === 2)}
                                                                            onChange={this.onChangepositioner}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="positioner_calibration2">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Accessories Tested</label>
                                                            <div className="row">
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="accessories_tested1"
                                                                            name="accessories_tested"
                                                                            type="radio"
                                                                            value="1"
                                                                            checked={(this.state.accessories_tested === 1)}
                                                                            onChange={this.onChangeacce}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="accessories_tested1">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="chk_col">
                                                                    <div className="custom-control custom-radio mb-3">
                                                                        <input
                                                                            className="custom-control-input"
                                                                            id="accessories_tested2"
                                                                            name="accessories_tested"
                                                                            type="radio"
                                                                            value="2"
                                                                            checked={(this.state.accessories_tested === 2)}
                                                                            onChange={this.onChangeacce}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="accessories_tested2">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Notes</label>
                                                            <Input className="form-control-alternative" placeholder="Notes" maxLength="100" name="testing_notes" value={this.state.testing_notes} onChange={this.onChangenotes} type="text" />
                                                        </FormGroup>
                                                        <FormGroup className="mb-2">
                                                            <label className="form-control-label">Technician Name</label>
                                                            <Input className="form-control-alternative" placeholder="Technician Name" ref="technician_name" value={this.state.technician_name} name="technician_name" type="text" onChange={this.onChangetechtxt} maxLength="50"/>
                                                            {/* <span className="error_cls">{this.state.errors["technician_name"]}</span> */}
                                                        </FormGroup>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col lg="6" md="6" sm="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Supervisor Sign</label>
                                                    <button type="button" onClick={this.Supmodal} className="btn sign_clearbtn">Sign</button>
                                                    
                                                    {SupertrimmedDataURL ? (
                                                        <img
                                                            src={SupertrimmedDataURL}
                                                            className="signimg"
                                                        />
                                                    ) : null}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input className="form-control-alternative" placeholder="Supervisor Name" ref="supervisor_name" maxLength="50" value={this.state.supervisor_name} name="supervisor_name" type="text" onChange={this.onChangesuptxt} />
                                                    <span className="error_cls">{this.state.errors["supervisor_name"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6" md="6" sm="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Engineer Sign</label>
                                                    <button type="button" onClick={this.enggmodal} className="btn sign_clearbtn">Sign</button>
                                                    
                                                    {EnggtrimmedDataURL ? (
                                                        <img
                                                            src={EnggtrimmedDataURL}
                                                            className="signimg"
                                                        />
                                                    ) : null}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input className="form-control-alternative" placeholder="Engineer Name" ref="engineer_name" name="engineer_name" maxLength="50" value={this.state.engineer_name} type="text" onChange={this.onChangeengtxt} />
                                                    <span className="error_cls">{this.state.errors["engineer_name"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" className="text-center">
                                                <FormGroup className="btn_formgrp">
                                                    <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                                                        <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                                                        <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                                                    </button>
                                                    <button type="button" className="btn btn-danger btn_save" onClick={this.valveTestSubmit}>Save</button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                                                        <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                                                        <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                                                    </button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    <Modal isOpen={this.state.supmodal}>
                        <form>
                        <div className="modal-header">
                            <h3 className="modal-title">Supervisor Sign</h3>
                            <button type="button" onClick={this.closeSupmodal} className="btn fa fa-times modal_close"></button>
                        </div>
                        <div className="modal-body">
                            <SignatureCanvas
                                ref={(ref) => { this.supPad = ref }}
                                canvasProps={{ className: 'sigCanvas signPad' }} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={this.saveSupersign}>
                                Save
                                </button>
                            <button type="button" className="btn btn-secondary" onClick={this.superclear}>
                                Clear
                                </button>
                        </div>
                        </form>
                    </Modal>
                    <Modal isOpen={this.state.engmodal}>
                        <form>
                        <div className="modal-header">
                            <h3 className="modal-title">Engineer Sign</h3>
                            <button type="button" onClick={this.closeengmodal} className="fa fa-times btn modal_close"></button>
                        </div>
                        <div className="modal-body">
                            <SignaturePad
                                ref={(ref) => { this.enggPad = ref }}
                                canvasProps={{ className: 'sigCanvas signPad' }} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={this.saveEnggsign}>
                                Save
                                </button>
                            <button type="button" className="btn btn-secondary" onClick={this.enggclear}>
                                Clear
                                </button>
                        </div>
                        </form>
                    </Modal>
            </>
        );
    }
}

export default valve_testing;
