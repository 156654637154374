import React from "react";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  //CardTitle,
  CardBody,
  FormGroup,
  //Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
class open_inspection_image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      img_1: '',
      desc_1: '',
      desc1: false,
      img_2: '',
      desc_2: '',
      desc2: false,
      img_3: '',
      desc_3: '',
      desc3: false,
      is_upload_img1: 0,
      is_upload_img2: 0,
      is_upload_img3: 0,
      page_path: "Dashboard",
      
      rmv_img1: false,
      openimg1: false,
      rmv_img2: false,
      openimg2: false,
      rmv_img3: false,
      openimg3: false,
    };
    this.fileSelectedHandler1 = this.fileSelectedHandler1.bind(this);
    this.fileSelectedHandler2 = this.fileSelectedHandler2.bind(this);
    this.fileSelectedHandler3 = this.fileSelectedHandler3.bind(this);
    this.GoToDashboard = this.GoToDashboard.bind(this);
    this.onchangeDes1 = this.onchangeDes1.bind(this);
    this.onchangeDes2 = this.onchangeDes2.bind(this);
    this.onchangeDes3 = this.onchangeDes3.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.PreviousClick = this.PreviousClick.bind(this);
    this.NextClick = this.NextClick.bind(this);
    this.NextSaveClick = this.NextSaveClick.bind(this);
    this.PrevSaveClick = this.PrevSaveClick.bind(this);

    this.btn_rmv_img1 = this.btn_rmv_img1.bind(this);
    this.btn_rmv_img2 = this.btn_rmv_img2.bind(this);
    this.btn_rmv_img3 = this.btn_rmv_img3.bind(this);
  }
  GoToDashboard() {
    this.props.history.push('./Dashboard');
  }
  PreviousClick(){
    this.props.history.push('./open_inspection');
  }
  NextClick(){
    this.props.history.push('./general_services');
  }
  NextSaveClick(){
    this.setState({
      page_path: "general_services",
    });
    this.handleSubmit();
  }
  PrevSaveClick(){
    this.setState({
      page_path: "open_inspection",
    });
    this.handleSubmit();
  }
  //select module
  handleModuleChange(e) {
    if (this.refs.select_module.value === 'Customer & Job') {
      this.props.history.push('./Customer');
    }
    else if (this.refs.select_module.value === 'Problems & Open Inspection') {
      this.props.history.push('./open_inspection');
    }
    else if (this.refs.select_module.value === 'Open Inspection Image') {
      this.props.history.push('./open_inspection_image');
    }
    else if (this.refs.select_module.value === 'General Service') {
      this.props.history.push('./general_services');
    }
    else if (this.refs.select_module.value === 'Valve Repair') {
      this.props.history.push('./valve_repair');
    }
    else if (this.refs.select_module.value === 'Actuator Repair') {
      this.props.history.push('./actuator_repair');
    }
    else if (this.refs.select_module.value === 'Accessories Detail') {
      this.props.history.push('./accessories_detail');
    }
    else if (this.refs.select_module.value === 'Valve Testing') {
      this.props.history.push('./valve_testing');
    }
    else if (this.refs.select_module.value === 'Repaired Images') {
      this.props.history.push('./repaired_image');
    }
    else if (this.refs.select_module.value === 'Dispatch') {
      this.props.history.push('./dispatch');
    }
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
      const jobData = {
        job_id: localStorage['job_id'],
        module_name: "job_open_inspection_img"
      }
      
      axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
        .then(result => {
          this.setState({
            desc1: (result.data.job_detail.img_1 != '' && result.data.job_detail.img_1 != null) ? true : false,
            desc2: (result.data.job_detail.img_2 != '' && result.data.job_detail.img_2 != null) ? true : false,
            desc3: (result.data.job_detail.img_3 != '' && result.data.job_detail.img_3 != null) ? true : false,
            img_1: (result.data.job_detail.img_1 != '' && result.data.job_detail.img_1 != null) ? result.data.base_url + "/" + result.data.job_detail.img_1 : "",
            desc_1: result.data.job_detail.desc_1,
            img_2: (result.data.job_detail.img_2 != '' && result.data.job_detail.img_2 != null) ? result.data.base_url + "/" + result.data.job_detail.img_2 : "",
            desc_2: result.data.job_detail.desc_2,
            img_3: (result.data.job_detail.img_3 != '' && result.data.job_detail.img_3 != null) ? result.data.base_url + "/" + result.data.job_detail.img_3 : "",
            desc_3: result.data.job_detail.desc_3,

            rmv_img1: (result.data.job_detail.img_1 != '' && result.data.job_detail.img_1 != null) ? true : false,
            openimg1: (result.data.job_detail.img_1 != '' && result.data.job_detail.img_1 != null) ? true : false,
            rmv_img2: (result.data.job_detail.img_2 != '' && result.data.job_detail.img_2 != null) ? true : false,
            openimg2: (result.data.job_detail.img_2 != '' && result.data.job_detail.img_2 != null) ? true : false,
            rmv_img3: (result.data.job_detail.img_3 != '' && result.data.job_detail.img_3 != null) ? true : false,
            openimg3: (result.data.job_detail.img_3 != '' && result.data.job_detail.img_3 != null) ? true : false,
          });
        }).catch(function (error) {
          console.log(error);
        });
    }
  }


  componentWillUnmount() {
    this._isMounted = false;
  }

  onchangeDes1(e) {
    this.setState({
      desc_1: e.target.value,
    });
  }
  onchangeDes2(e) {
    this.setState({
      desc_2: e.target.value,
    });
  }
  onchangeDes3(e) {
    this.setState({
      desc_3: e.target.value,
    });
  }
  //file1 upload code
  fileSelectedHandler1 = (event) => {
    console.log(event.target.files.length);
    this.setState({
      selectedFile: event.target.files[0],
    }, () => { console.log(this.state.selectedFile) });

    if (event.target.files.length > 0) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(event.target.files[0]);

      reader.onloadend = function (e) {
        this.setState({
          img_1: reader.result,
          desc1: true,
          openimg1: true,
          rmv_img1: true,
          is_upload_img1: 1,
        })
      }.bind(this);
    } else {
      this.setState({
        selectedFile: '',
        img_1: '',
        desc1: false,
        openimg1: false,
        rmv_img1: false,
        is_upload_img1: 0,
      })
    }
  };
  btn_rmv_img1(){
    this.setState({
        selectedFile: '',
        img_1: '',
        desc_1: '',
        desc1: false,
        openimg1: false,
        rmv_img1: false,
    })
    let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
    const saveImgData = {
        job_id: localStorage['job_id'],
        table_name: "job_open_inspection_img",
        column_name: "img_1"
    }
    axios.post(server_config.API_BASE_URL+"removeImage", saveImgData, { headers: { "Authorization": "Bearer " + api_token } });
}
  //file2 upload code
  fileSelectedHandler2 = (event) => {
    console.log(event.target.files.length);

    if (event.target.files.length > 0) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(event.target.files[0]);

      reader.onloadend = function (e) {
        this.setState({
          img_2: reader.result,
          desc2: true,
          openimg2: true,
        rmv_img2: true,
          is_upload_img2: 1,
        })
      }.bind(this);
    } else {
      this.setState({
        img_2: '',
        desc2: false,
        openimg2: false,
        rmv_img2: false,
        is_upload_img2: 0,
      })
    }
  };
  btn_rmv_img2(){
    this.setState({
        img_2: '',
        desc_2: '',
        desc2: false,
        openimg2: false,
        rmv_img2: false,
    })
    let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
    const saveImgData = {
        job_id: localStorage['job_id'],
        table_name: "job_open_inspection_img",
        column_name: "img_2"
    }
    axios.post(server_config.API_BASE_URL+"removeImage", saveImgData, { headers: { "Authorization": "Bearer " + api_token } });
}
  //file3 upload code
  fileSelectedHandler3 = (event) => {
    console.log(event.target.files.length);

    if (event.target.files.length > 0) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(event.target.files[0]);

      reader.onloadend = function (e) {
        this.setState({
          img_3: reader.result,
          desc3: true,
          openimg3: true,
        rmv_img3: true,
          is_upload_img3: 1,
        })
      }.bind(this);
    } else {
      this.setState({
        img_3: '',
        desc3: false,
        openimg3: false,
        rmv_img3: false,
        is_upload_img3: 0,
      })
    }
  };
  btn_rmv_img3(){
    this.setState({
      img_3: '',
      desc_3: '',
      desc3: false,
        openimg3: false,
        rmv_img3: false,
    })
    let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
    const saveImgData = {
        job_id: localStorage['job_id'],
        table_name: "job_open_inspection_img",
        column_name: "img_3"
    }
    axios.post(server_config.API_BASE_URL+"removeImage", saveImgData, { headers: { "Authorization": "Bearer " + api_token } });
}
  handleSubmit() {
    $("#root").addClass("disable_root");
    $("#loader").removeAttr("style").addClass("show_loader");
    let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
    const savejobData = {
      job_id: localStorage['job_id'],
      module_name: "job_open_inspection_img",
      img_1: this.state.img_1,
      img_2: this.state.img_2,
      img_3: this.state.img_3,
      desc_1: this.state.desc_1,
      desc_2: this.state.desc_2,
      desc_3: this.state.desc_3,
      is_upload_img1: this.state.is_upload_img1,
      is_upload_img2: this.state.is_upload_img2,
      is_upload_img3: this.state.is_upload_img3,
    }
    axios.post(server_config.API_BASE_URL+"updateJobModuleImage", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
      .then(result => {
        if(this.state.page_path == "Dashboard"){
          localStorage['form_submit_alert'] = "Open Inspection Image Updated Successfully";
        }
        $("#root").removeClass("disable_root");
        $("#loader").removeClass("show_loader").css("display", "none");
        // alert("Open Inspection Image Updated SuccessFully");
        this.props.history.push("./"+this.state.page_path);
      }).catch(function (error) {
        // if (axios.isCancel(error)) {
        //   console.log('Request canceled', error);
        // }
        $("#root").removeClass("disable_root");
        $("#loader").removeClass("show_loader").css("display", "none");
        alert(error);
      });
  }
  render() {
    const { desc1, desc2, desc3, rmv_img1, rmv_img2, rmv_img3, openimg1, openimg2, openimg3 } = this.state;
    return (
      <>
        <div className="header pt-5">
          <Container fluid>
            <div className="header-body">
              <Card className="bg-secondary shadow border-0 mb-3">
                <CardBody>
                  <Row>
                    <Col lg="12">
                    <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                      <select ref="select_module" defaultValue="Open Inspection Image" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                        <option value="Customer & Job">Customer & Job</option>
                        <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                        <option value="Open Inspection Image">Open Inspection Image</option>
                        <option value="General Service">General Service</option>
                        <option value="Valve Repair">Valve Repair</option>
                        <option value="Actuator Repair">Actuator Repair</option>
                        <option value="Repaired Images">Repaired Images</option>
                        <option value="Accessories Detail">Accessories Detail</option>
                        <option value="Valve Testing">Valve Testing</option>
                        <option value="Dispatch">Dispatch</option>
                      </select>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-3">
                  <div>
                    <label className="mb-0 job_title">Open Inspection Image</label>
                    <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                      <i className="ni ni-bold-left back_icon" />
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col lg="4" md="12" sm="12">
                        <FormGroup>
                          <label className="form-control-label">Upload Image</label><br></br>

                          <label htmlFor="myInput"><i className="ni ni-camera-compact cam_icon"></i></label>

                          <input
                            id="myInput"
                            style={{ display: 'none' }}
                            type={"file"}
                            capture="camera"
                            accept=".png, .jpg, .jpeg"
                            onChange={this.fileSelectedHandler1}
                          />
                          <br></br>
                          {openimg1 && (
                          <img src={this.state.img_1} className="preview_img" />
                          )}
                          {desc1 && (
                            <Input className="form-control-alternative" placeholder="Description" name="desc1" type="text" value={this.state.desc_1} onChange={this.onchangeDes1} />
                          )}
                          {rmv_img1 && (
                              <button type="button" title="Remove" className="remove" onClick={this.btn_rmv_img1}><i className="ni ni-fat-remove"></i></button>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4" md="12" sm="12">
                        <FormGroup>
                          <label className="form-control-label">Upload Image</label><br></br>

                          <label htmlFor="myInput1"><i className="ni ni-camera-compact cam_icon"></i></label>

                          <input
                            id="myInput1"
                            style={{ display: 'none' }}
                            type={"file"}
                            capture="camera"
                            accept=".png, .jpg, .jpeg"
                            onChange={this.fileSelectedHandler2}
                          />
                          <br></br>
                          {openimg2 && (
                          <img src={this.state.img_2} className="preview_img" />
                          )}
                          {desc2 && (
                            <Input className="form-control-alternative" placeholder="Description" name="desc2" type="text" value={this.state.desc_2} onChange={this.onchangeDes2} />
                          )}
                          {rmv_img2 && (
                              <button type="button" title="Remove" className="remove" onClick={this.btn_rmv_img2}><i className="ni ni-fat-remove"></i></button>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4" md="12" sm="12">
                        <FormGroup>
                          <label className="form-control-label">Upload Image</label><br></br>

                          <label htmlFor="myInput2"><i className="ni ni-camera-compact cam_icon"></i></label>

                          <input
                            id="myInput2"
                            style={{ display: 'none' }}
                            type={"file"}
                            capture="camera"
                            accept=".png, .jpg, .jpeg"
                            onChange={this.fileSelectedHandler3}
                          />
                          <br></br>
                          {openimg3 && (
                          <img src={this.state.img_3} className="preview_img" />
                          )}
                          {desc3 && (
                            <Input className="form-control-alternative" placeholder="Description" name="desc3" type="text" value={this.state.desc_3} onChange={this.onchangeDes3} />
                          )}
                          {rmv_img3 && (
                              <button type="button" title="Remove" className="remove" onClick={this.btn_rmv_img3}><i className="ni ni-fat-remove"></i></button>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="12" className="text-center">
                        <FormGroup  className="btn_formgrp">
                          <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                            <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                          </button>
                          <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                            <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                          </button>
                          <button type="button" onClick={this.handleSubmit} className="btn btn-danger btn_save">Save</button>
                          <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                            <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                          </button>
                          <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                            <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                          </button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default open_inspection_image;
