import React from "react";

// reactstrap components
import {
    // Button,
    Card,
    CardHeader,
    //CardTitle,
    CardBody,
    FormGroup,
    //Form,
    Input,
    Container,
    Row,
    Col,
    InputGroupAddon,
    InputGroupText,
    InputGroup, 
    // Modal,
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
//import Popup from 'reactjs-popup';
// import ReactDatetime from "react-datetime";
class dispatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            dispatch_img: '',
            rmv_disptach_btn: false,
            disimg: false,
            img_desc: '',
            // camera_btn: true,
            desc: false,
            errors: {},
            is_submit_click: 0,
            job_status: 1,
            challan_no: "",
            challan_date: "",
            vehicle_no: "",
            driver_name: "",
            is_upload_img: 0,
            page_path: "Dashboard",
        };
        this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
        this.dispatchSubmit = this.dispatchSubmit.bind(this);
        this.GoToDashboard = this.GoToDashboard.bind(this);
        this.handleModuleChange = this.handleModuleChange.bind(this);
        this.onChangejobstatus = this.onChangejobstatus.bind(this);
        this.onChangeChallanDate = this.onChangeChallanDate.bind(this);
        this.onChangeChallan = this.onChangeChallan.bind(this);
        this.onChangeimgdesc = this.onChangeimgdesc.bind(this);
        this.inputValidation = this.inputValidation.bind(this);
        this.PreviousClick = this.PreviousClick.bind(this);
        this.PrevSaveClick = this.PrevSaveClick.bind(this);
        this.btn_rmv_dis = this.btn_rmv_dis.bind(this);
        this.onChangeVehicle = this.onChangeVehicle.bind(this);
        this.onChangeDriver = this.onChangeDriver.bind(this);
    }
    PreviousClick(){
        this.props.history.push('./valve_testing');
    }
    PrevSaveClick(){
        this.setState({
            page_path: "valve_testing",
        });
        this.dispatchSubmit();
    }
    GoToDashboard() {
        this.props.history.push('./Dashboard');
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const jobData = {
                job_id: localStorage['job_id'],
                module_name: "job_dispatch"
            }
            axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {

                    this.setState({
                        job_status: result.data.job_detail.job_status,
                        challan_no: result.data.job_detail.challan_no,
                        challan_date: result.data.job_detail.challan_date,
                        vehicle_no: result.data.job_detail.vehicle_no,
                        driver_name: result.data.job_detail.driver_name,
                        desc: (result.data.job_detail.dispatch_img != '' && result.data.job_detail.dispatch_img != null) ? true : false,
                        dispatch_img: (result.data.job_detail.dispatch_img != '' && result.data.job_detail.dispatch_img != null) ? result.data.base_url + "/" + result.data.job_detail.dispatch_img : "",
                        img_desc: result.data.job_detail.img_desc,
                        disimg: (result.data.job_detail.dispatch_img != '' && result.data.job_detail.dispatch_img != null) ? true : false,
                        rmv_disptach_btn: (result.data.job_detail.dispatch_img != '' && result.data.job_detail.dispatch_img != null) ? true : false,
                    });
                }).catch(function (error) {
                    //console.log(error);
                });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //select module
    handleModuleChange(e) {
        if (this.refs.select_module.value === 'Customer & Job') {
            this.props.history.push('./Customer');
        }
        else if (this.refs.select_module.value === 'Problems & Open Inspection') {
            this.props.history.push('./open_inspection');
        }
        else if (this.refs.select_module.value === 'Open Inspection Image') {
            this.props.history.push('./open_inspection_image');
        }
        else if (this.refs.select_module.value === 'General Service') {
            this.props.history.push('./general_services');
        }
        else if (this.refs.select_module.value === 'Valve Repair') {
            this.props.history.push('./valve_repair');
        }
        else if (this.refs.select_module.value === 'Actuator Repair') {
            this.props.history.push('./actuator_repair');
        }
        else if (this.refs.select_module.value === 'Accessories Detail') {
            this.props.history.push('./accessories_detail');
        }
        else if (this.refs.select_module.value === 'Valve Testing') {
            this.props.history.push('./valve_testing');
        }
        else if (this.refs.select_module.value === 'Repaired Images') {
            this.props.history.push('./repaired_image');
        }
        else if (this.refs.select_module.value === 'Dispatch') {
            this.props.history.push('./dispatch');
        }
    }
    //file upload code
    fileSelectedHandler = (event) => {
        console.log(event.target.files.length);
        this.setState({
            selectedFile: event.target.files[0],
            // filename : event.target.files[0].name
        });

        if (event.target.files.length > 0) {
            var reader = new FileReader();
            var url = reader.readAsDataURL(event.target.files[0]);

            reader.onloadend = function (e) {
                this.setState({
                    dispatch_img: reader.result,
                    desc: true,
                    rmv_disptach_btn: true,
                    disimg: true,
                    is_upload_img: 1,
                })
            }.bind(this);
        } else {
            this.setState({
                selectedFile: '',
                dispatch_img: '',
                desc: false,
                disimg: false,
                rmv_disptach_btn: false,
                is_upload_img: 0,
            })
        }

    };
    btn_rmv_dis(){
        this.setState({
            selectedFile: '',
            dispatch_img: '',
            img_desc: '',
            desc: false,
            disimg: false,
            rmv_disptach_btn: false,
        })
        let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
        const saveImgData = {
            job_id: localStorage['job_id'],
            table_name: "job_dispatch",
            column_name: "dispatch_img"
        }
        axios.post(server_config.API_BASE_URL+"removeImage", saveImgData, { headers: { "Authorization": "Bearer " + api_token } });
    }
    handleValidation(e) {
        let errors = {};
        let formIsValid = true;

        //Name
        if (!this.state.challan_no) {
            formIsValid = false;
            errors["challan_no"] = "Please Enter Challan No.";
        }
        if (!this.state.challan_date) {
            formIsValid = false;
            errors["challan_date"] = "Please Enter Challan Date";
        }
        if (!this.state.vehicle_no) {
            formIsValid = false;
            errors["vehicle_no"] = "Please Enter Vehicle No.";
        }
        if (!this.state.driver_name) {
            formIsValid = false;
            errors["driver_name"] = "Please Enter Driver Name";
        }
        this.setState({ errors: errors });
        if (e == 1) {
            return formIsValid;
        }
    }
    inputValidation(e) {
        if (e === 1) {
            this.setState({
                challan_no: this.state.challan_no,
            }, () => { this.handleValidation(0) });
        }
    }
    onChangeChallan(e) {
        this.setState({
            challan_no: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onChangeChallanDate(e) {
        this.setState({
            challan_date: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onChangeVehicle(e) {
        this.setState({
            vehicle_no: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onChangeDriver(e) {
        this.setState({
            driver_name: e.target.value,
        }, this.inputValidation(this.state.is_submit_click));
    }
    onChangejobstatus(e) {
        this.setState({
            job_status: parseInt(e.target.value),
        });
    }
    onChangeimgdesc(e) {
        this.setState({
            img_desc: e.target.value,
        });
    }
    dispatchSubmit() {
        // e.preventDefault();
        this.setState({
            is_submit_click: 1
        });
        if (this.handleValidation(1)) {
            $("#root").addClass("disable_root");
            $("#loader").removeAttr("style").addClass("show_loader");
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const savejobData = {
                job_id: localStorage['job_id'],
                job_status: this.state.job_status,
                challan_no: this.state.challan_no,
                challan_date: this.state.challan_date,
                vehicle_no: this.state.vehicle_no,
                driver_name: this.state.driver_name,
                dispatch_img: this.state.dispatch_img,
                img_desc: this.state.img_desc,
                is_upload_img: this.state.is_upload_img,
                engineer_sign: this.state.EnggtrimmedDataURL,
                supervisor_sign: this.state.SupertrimmedDataURL,
                technician_sign: this.state.TechtrimmedDataURL,
                is_engg_sign: this.state.is_engg_sign,
                is_super_sign: this.state.is_super_sign,
                is_tech_sign: this.state.is_tech_sign,
                technician_name: this.state.technician_name,
                supervisor_name: this.state.supervisor_name,
                engineer_name: this.state.engineer_name
            }
            axios.post(server_config.API_BASE_URL+"updateJobDispatch", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    if(this.state.page_path == "Dashboard"){
                        localStorage['form_submit_alert'] = "Dispatch Detail Updated Successfully";
                    }
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                    // alert("Dispatch Detail Updated SuccessFully");
                    this.props.history.push("./"+this.state.page_path);
                }).catch(function (error) {
                    alert(error);
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                });
        } else {
            alert("Please fill up all mandatory fields.");
            window.scrollTo(0, 0);
        }
    }
    render() {
        const { desc,rmv_disptach_btn,disimg } = this.state;
        return (
            <>
                <div className="header pt-5">
                    <Container fluid>
                        <div className="header-body">
                            <Card className="bg-secondary shadow border-0 mb-3">
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                            <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                                            <select ref="select_module" defaultValue="Dispatch" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                                                <option value="Customer & Job">Customer & Job</option>
                                                <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                                                <option value="Open Inspection Image">Open Inspection Image</option>
                                                <option value="General Service">General Service</option>
                                                <option value="Valve Repair">Valve Repair</option>
                                                <option value="Actuator Repair">Actuator Repair</option>
                                                <option value="Repaired Images">Repaired Images</option>
                                                <option value="Accessories Detail">Accessories Detail</option>
                                                <option value="Valve Testing">Valve Testing</option>
                                                <option value="Dispatch">Dispatch</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                </div>
                {/* Page content */}
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-3">
                                    <div>
                                        <label className="mb-0 job_title">Dispatch</label>
                                        <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                                            <i className="ni ni-bold-left back_icon" />
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup className="mb-2">
                                                    <label className="form-control-label">Job Status</label>
                                                    <div className="row">
                                                        <div className="col_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="ongoing_chk"
                                                                    name="job_status"
                                                                    type="radio"
                                                                    value="1"
                                                                    checked={(this.state.job_status === 1)}
                                                                    onChange={this.onChangejobstatus}
                                                                />
                                                                <label className="custom-control-label" htmlFor="ongoing_chk">
                                                                    Ongoing
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="completed_chk"
                                                                    name="job_status"
                                                                    type="radio"
                                                                    value="3"
                                                                    checked={(this.state.job_status === 3)}
                                                                    onChange={this.onChangejobstatus}
                                                                />
                                                                <label className="custom-control-label" htmlFor="completed_chk">
                                                                    Completed
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col_div">
                                                            <div className="custom-control custom-radio mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id="closed_chk"
                                                                    name="job_status"
                                                                    type="radio"
                                                                    value="2"
                                                                    checked={(this.state.job_status === 2)}
                                                                    onChange={this.onChangejobstatus}
                                                                />
                                                                <label className="custom-control-label" htmlFor="closed_chk">
                                                                    Closed
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="font-weight-bold">Challan No.<span className="text-danger"> *</span></label>
                                                    <Input className="form-control-alternative" ref="challan_no" type="text" name="challan_no" value={this.state.challan_no} maxLength="30" onChange={this.onChangeChallan} />
                                                    <span className="error_cls">{this.state.errors["challan_no"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="font-weight-bold">Challan Date<span className="text-danger"> *</span></label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-calendar-grid-58" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        {/* <ReactDatetime
                                                            name="challan_date"
                                                            inputProps={{
                                                                placeholder: "mm/dd/yyyy"
                                                            }}
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                        /> */}
                                                        <Input className="form-control-alternative" ref="challan_date" placeholder="dd-mmm-yyyy" type="Date" name="challan_date" value={this.state.challan_date} onChange={this.onChangeChallanDate} />
                                                    </InputGroup>
                                                    <span className="error_cls">{this.state.errors["challan_date"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="font-weight-bold">Vehicle No.<span className="text-danger"> *</span></label>
                                                    <Input className="form-control-alternative" ref="vehicle_no" type="text" name="vehicle_no" value={this.state.vehicle_no} maxLength="50" onChange={this.onChangeVehicle} />
                                                    <span className="error_cls">{this.state.errors["vehicle_no"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="font-weight-bold">Driver Name<span className="text-danger"> *</span></label>
                                                    <Input className="form-control-alternative" ref="driver_name" type="text" name="driver_name" value={this.state.driver_name} maxLength="50" onChange={this.onChangeDriver} />
                                                    <span className="error_cls">{this.state.errors["driver_name"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4" md="12" sm="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Upload </label><br></br>

                                                    <label htmlFor="myInput"><i className="ni ni-camera-compact cam_icon"></i></label>

                                                    <input
                                                        id="myInput"
                                                        style={{ display: 'none' }}
                                                        type={"file"}
                                                        capture="camera"
                                                        accept=".png, .jpg, .jpeg"
                                                        onChange={this.fileSelectedHandler}
                                                    />
                                                    <br></br>
                                                    {disimg && (
                                                    <img src={this.state.dispatch_img} className="preview_img" />
                                                    )}
                                                    {desc && (
                                                        <Input className="text-center form-control-alternative" readOnly placeholder="Description" maxLength="100" name="desc" type="text" value="As Disptached" onChange={this.onChangeimgdesc} />
                                                    )}
                                                    {rmv_disptach_btn && (
                                                        <button type="button" title="Remove" className="remove" onClick={this.btn_rmv_dis}><i className="ni ni-fat-remove"></i></button>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" className="text-center">
                                                <FormGroup className="btn_formgrp">
                                                    <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                                                        <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                                                    </button>
                                                    <button className="btn-icon btn btn-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                                                        <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                                                    </button>
                                                    <button type="button" className="btn btn-outline-danger btn_save" onClick={this.dispatchSubmit}>Save</button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                
            </>
        );
    }
}

export default dispatch;
