import React from "react";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  // CardTitle,
  CardBody,
  FormGroup,
  // Form,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      imgSrc: '',
      rmv_btn: false,
      cust_img: false,
      errors: {},
      is_submit_click: 0,
      cus_id: "",
      customerDropdown: [],
      job_type: 1,
      report_date: "",
      hts_no: "",
      hts_transport_pickup: 0,
      hts_transport_delivery: 0,
      leackage_class: "",
      quote_to: "",
      plant: "",
      valve_type: "",
      valve_tag_no: "",
      valve_desc: "",
      size: "",
      ogp_no: "",
      ogp_date: "",
      make_model: "",
      lead_time: "",
      datasheet: 1,
      fail_action: 1,
      is_upload_img: 0,
      page_path: "Dashboard",
      show_input: false,
      show_dropdown: true,
    };
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
    this.onChangeReportDate = this.onChangeReportDate.bind(this);
    this.onChangeHTS = this.onChangeHTS.bind(this);
    this.onChangeLeackclass = this.onChangeLeackclass.bind(this);
    this.onChangePlant = this.onChangePlant.bind(this);
    this.onChangeQuote = this.onChangeQuote.bind(this);
    this.onChangeValveType = this.onChangeValveType.bind(this);
    this.onChangeValveTag = this.onChangeValveTag.bind(this);
    this.onChangeValveDesc = this.onChangeValveDesc.bind(this);
    this.onChangeSize = this.onChangeSize.bind(this);
    this.onChangeOGP = this.onChangeOGP.bind(this);
    this.onChangeOGPDate = this.onChangeOGPDate.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.onChangeLead = this.onChangeLead.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.customerSubmit = this.customerSubmit.bind(this);
    this.GoToDashboard = this.GoToDashboard.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);
    this.onChangecustomer = this.onChangecustomer.bind(this);
    this.onChangejobtype = this.onChangejobtype.bind(this);
    this.onChangetrans = this.onChangetrans.bind(this);
    this.onChangedelivery = this.onChangedelivery.bind(this);
    this.onChangedatasheet = this.onChangedatasheet.bind(this);
    this.onchangeFail = this.onchangeFail.bind(this);
    this.inputValidation = this.inputValidation.bind(this);
    this.NextClick = this.NextClick.bind(this);
    this.NextSaveClick = this.NextSaveClick.bind(this);
    this.btn_rmv_cusotmer = this.btn_rmv_cusotmer.bind(this);
  }
  GoToDashboard() {
    this.props.history.push('./Dashboard');
  }
  NextClick(){
    this.props.history.push('./open_inspection');
  }
  NextSaveClick(){
    this.setState({
      page_path: "open_inspection",
    });
    this.customerSubmit();
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
      const jobData = {
        job_id: localStorage['job_id'],
      }
      axios.post(server_config.API_BASE_URL+"getCustomerDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
        .then(result => {
          if(result.data.selected_customer.is_active == 0){
              this.setState({
                //show readonly input
                //hide drop down 
                show_input: true,
                selected_customer: result.data.selected_customer.name,
                show_dropdown: false,
              });
          }else{
            this.setState({
                //show drop down
                //hide input
                show_input: false,
                show_dropdown: true,
            });
          }
          result.data.customer_detail.forEach(customer => {
            this.setState({
              customerDropdown: this.state.customerDropdown.concat([{ c_id: customer.id, c_data: customer.name }]),
            });
          });
          this.setState({
            cus_id: result.data.job_detail.customer_id,
            job_type: result.data.job_detail.job_type,
            report_date: result.data.job_detail.report_date,
            hts_no: result.data.job_detail.hts_no,
            hts_transport_pickup: result.data.job_detail.hts_transport_pickup,
            hts_transport_delivery: result.data.job_detail.hts_transport_delivery,
            leackage_class: result.data.job_detail.leackage_class,
            quote_to: result.data.job_detail.quote_to,
            plant: result.data.job_detail.plant,
            valve_type: result.data.job_detail.valve_type,
            valve_tag_no: result.data.job_detail.valve_tag_no,
            valve_desc: result.data.job_detail.valve_desc,
            size: result.data.job_detail.size,
            ogp_no: result.data.job_detail.ogp_no,
            ogp_date: result.data.job_detail.ogp_date,
            make_model: result.data.job_detail.make_model,
            lead_time: result.data.job_detail.lead_time,
            datasheet: result.data.job_detail.datasheet,
            fail_action: result.data.job_detail.fail_action,
            imgSrc: (result.data.job_detail.customer_tag_image != '' && result.data.job_detail.customer_tag_image != null) ? result.data.base_url + "/" + result.data.job_detail.customer_tag_image : "",
            cust_img: (result.data.job_detail.customer_tag_image != '' && result.data.job_detail.customer_tag_image != null) ? true : false,
            rmv_btn: (result.data.job_detail.customer_tag_image != '' && result.data.job_detail.customer_tag_image != null) ? true : false,
          });
        }).catch(function (error) {
          //console.log(error);
        });
    }
  }


  componentWillUnmount() {
    this._isMounted = false;
  }

  fileSelectedHandler = (event) => {
    console.log(event.target.files.length);
    this.setState({
      selectedFile: event.target.files[0],
    });

    if (event.target.files.length > 0) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = function (e) {
        this.setState({
          imgSrc: reader.result,
          desc: true,
          rmv_btn: true,
          cust_img: true,
          is_upload_img: 1,
        })
      }.bind(this);
    } else {
      this.setState({
        selectedFile: '',
        imgSrc: '',
        desc: false,
        rmv_btn: false,
        cust_img: false,
        is_upload_img: 0,
      })
    }

  };
  btn_rmv_cusotmer(){
    this.setState({
      selectedFile: '',
      imgSrc: '',
      rmv_btn: false,
      cust_img: false,
    })
    let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
    const saveImgData = {
      job_id: localStorage['job_id'],
      table_name: "job_master",
      column_name: "customer_tag_image"
    }
    axios.post(server_config.API_BASE_URL+"removeImage", saveImgData, { headers: { "Authorization": "Bearer " + api_token } });
  }
  //validation check
  handleValidation(e) {
    let errors = {};
    let formIsValid = true;

    //Name
    if (!this.state.hts_no) {
      formIsValid = false;
      errors["hts_no"] = "Please Enter HTS No.";
    }
    if (!this.state.report_date) {
      formIsValid = false;
      errors["report_date"] = "Please Enter Report Date";
    }
    if (!this.state.size) {
      formIsValid = false;
      errors["size"] = "Please Enter Size";
    }
    if (!this.state.ogp_no) {
      formIsValid = false;
      errors["ogp_no"] = "Please Enter OGP#";
    }
    if (!this.state.ogp_date) {
      formIsValid = false;
      errors["ogp_date"] = "Please Enter OGP Date";
    }
    this.setState({ errors: errors });
    if (e == 1) {
      return formIsValid;
    }
  }

  inputValidation(e) {
    if (e === 1) {
      this.setState({
        hts_no: this.state.hts_no,
      }, () => { this.handleValidation(0) });
    }
  }

  onChangeHTS(e) {
    this.setState({
      hts_no: e.target.value,
    }, this.inputValidation(this.state.is_submit_click));
  }

  onChangeReportDate(e) {
    this.setState({
      report_date: e.target.value,
    }, this.inputValidation(this.state.is_submit_click));
  }

  onChangePlant(e) {
    this.setState({
      plant: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangeQuote(e) {
    this.setState({
      quote_to: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangeLeackclass(e) {
    this.setState({
      leackage_class: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangeValveType(e) {
    this.setState({
      valve_type: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangeValveTag(e) {
    this.setState({
      valve_tag_no: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangeValveDesc(e) {
    this.setState({
      valve_desc: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangeSize(e) {
    this.setState({
      size: e.target.value,
    }, this.inputValidation(this.state.is_submit_click));
  }
  onChangeOGP(e) {
    this.setState({
      ogp_no: e.target.value,
    }, this.inputValidation(this.state.is_submit_click));
  }
  onChangeOGPDate(e) {
    this.setState({
      ogp_date: e.target.value,
    }, this.inputValidation(this.state.is_submit_click));
  }
  onChangeModel(e) {
    this.setState({
      make_model: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangeLead(e) {
    this.setState({
      lead_time: e.target.value,
    }
    // , this.inputValidation(this.state.is_submit_click)
    );
  }
  onChangecustomer(e) {
    this.setState({
      cus_id: e.target.value
    }, this.inputValidation(this.state.is_submit_click));
  }
  onChangejobtype(e) {
    this.setState({
      job_type: parseInt(e.target.value),
    });
  }
  onChangetrans(e) {
    this.setState({
      hts_transport_pickup: (e.target.checked) ? 1 : 0,
    })
  }
  onChangedelivery(e) {
    this.setState({
      hts_transport_delivery: (e.target.checked) ? 1 : 0,
    });
  }
  onChangedatasheet(e) {
    this.setState({
      datasheet: parseInt(e.target.value),
    });
  }
  onchangeFail(e) {
    this.setState({
      fail_action: parseInt(e.target.value),
    });
  }
  // onchangeDes(e) {
  //   this.setState({
  //     imgdesc: e.target.value,
  //   });
  // }
  handleModuleChange(e) {
    if (this.refs.select_module.value === 'Customer & Job') {
      this.props.history.push('./Customer');
    }
    else if (this.refs.select_module.value === 'Problems & Open Inspection') {
      this.props.history.push('./open_inspection');
    }
    else if (this.refs.select_module.value === 'Open Inspection Image') {
      this.props.history.push('./open_inspection_image');
    }
    else if (this.refs.select_module.value === 'General Service') {
      this.props.history.push('./general_services');
    }
    else if (this.refs.select_module.value === 'Valve Repair') {
      this.props.history.push('./valve_repair');
    }
    else if (this.refs.select_module.value === 'Actuator Repair') {
      this.props.history.push('./actuator_repair');
    }
    else if (this.refs.select_module.value === 'Accessories Detail') {
      this.props.history.push('./accessories_detail');
    }
    else if (this.refs.select_module.value === 'Valve Testing') {
      this.props.history.push('./valve_testing');
    }
    else if (this.refs.select_module.value === 'Repaired Images') {
      this.props.history.push('./repaired_image');
    }
    else if (this.refs.select_module.value === 'Dispatch') {
      this.props.history.push('./dispatch');
    }
  }
  customerSubmit() {
    // e.preventDefault();
    this.setState({
      is_submit_click: 1
    });
    if (this.handleValidation(1)) {
      $("#root").addClass("disable_root");
      $("#loader").removeAttr("style").addClass("show_loader");
      let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
      const savejobData = {
        job_id: localStorage['job_id'],
        customer_id: this.state.cus_id,
        job_type: this.state.job_type,
        report_date: this.state.report_date,
        hts_no: this.state.hts_no,
        hts_transport_pickup: this.state.hts_transport_pickup,
        hts_transport_delivery: this.state.hts_transport_delivery,
        leackage_class: this.state.leackage_class,
        quote_to: this.state.quote_to,
        plant: this.state.plant,
        valve_type: this.state.valve_type,
        valve_tag_no: this.state.valve_tag_no,
        valve_desc: this.state.valve_desc,
        size: this.state.size,
        ogp_no: this.state.ogp_no,
        ogp_date: this.state.ogp_date,
        make_model: this.state.make_model,
        datasheet: this.state.datasheet,
        fail_action: this.state.fail_action,
        lead_time: this.state.lead_time,
        customerFile: this.state.imgSrc,
        is_upload_img: this.state.is_upload_img,
      }
      
      axios.post(server_config.API_BASE_URL+"updateCustomerJob", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
        .then(result => {
          if(this.state.page_path == "Dashboard"){
            localStorage['form_submit_alert'] = "Customer Details Updated Successfully";
          }
          $("#root").removeClass("disable_root");
          $("#loader").removeClass("show_loader").css("display", "none");
          // alert("Customer Details Updated SuccessFully");
          this.props.history.push("./"+this.state.page_path);
        }).catch(function (error) {
          alert(error);
          $("#root").removeClass("disable_root");
          $("#loader").removeClass("show_loader").css("display", "none");
        });
    } else {
      alert("Please fill up all mandatory fields.");
      window.scrollTo(0, 0);
    }
  }
  render() {
    const { show_input, show_dropdown, rmv_btn, cust_img } = this.state;
    return (
      <>
        <div className="header pt-5">
          <Container fluid>
            <div className="header-body">
              <Card className="bg-secondary shadow border-0 mb-3">
                <CardBody>
                  <Row>
                    <Col lg="12">
                    <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                      <select ref="select_module" defaultValue="Customer & Job" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                        <option value="Customer & Job">Customer & Job</option>
                        <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                        <option value="Open Inspection Image">Open Inspection Image</option>
                        <option value="General Service">General Service</option>
                        <option value="Valve Repair">Valve Repair</option>
                        <option value="Actuator Repair">Actuator Repair</option>
                        <option value="Repaired Images">Repaired Images</option>
                        <option value="Accessories Detail">Accessories Detail</option>
                        <option value="Valve Testing">Valve Testing</option>
                        <option value="Dispatch">Dispatch</option>
                      </select>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-3">
                  <div>
                    <label className="mb-0 job_title">Customer</label>
                    <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                      <i className="ni ni-bold-left back_icon" />
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Job Type</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="job_type1"
                                  name="job_type"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.job_type === 1)}
                                  onChange={this.onChangejobtype}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="job_type1">
                                  Job
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="job_type2"
                                  name="job_type"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.job_type === 2)}
                                  onChange={this.onChangejobtype}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="job_type2">
                                  Quotation
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                      {show_dropdown && (
                        <FormGroup>
                          <label className="form-control-label">Select Customer<span className="text-danger"> *</span></label>
                          <select className="form-control form-control-alternative" name="customer_id" value={this.state.cus_id} onChange={this.onChangecustomer}>
                            {this.state.customerDropdown.map((customer, idx) => (
                              <option value={customer.c_id} key={customer.c_id}>{customer.c_data}</option>
                            ))}
                          </select>
                        </FormGroup>
                        )}
                        {show_input && (
                          <FormGroup>
                            <label className="form-control-label">Customer</label>
                            <Input ref="selected_customer" readonly="true" className="form-control-alternative" name="selected_customer" value={this.state.selected_customer} type="text" />
                        </FormGroup>
                        )}
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Report Date<span className="text-danger"> *</span></label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            {/* <ReactDatetime
                              name="report_date"
                              inputProps={{
                                placeholder: "mm/dd/yyyy"
                              }}
                              timeFormat={false}
                              closeOnSelect={true} 
                            /> */}
                            <Input ref="report_date" className="form-control-alternative" id="report_date" placeholder="dd-mmm-yyyy" name="report_date" value={this.state.report_date} type="Date" onChange={this.onChangeReportDate} />
                          </InputGroup>
                          <span className="error_cls">{this.state.errors["report_date"]}</span>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">HTS# <span className="text-danger"> *</span></label>
                          <Input ref="hts_no" className="form-control-alternative" placeholder="HTS#" type="text" name="hts_no" value={this.state.hts_no} maxLength="30" onChange={this.onChangeHTS} />
                          <span className="error_cls">{this.state.errors["hts_no"]}</span>
                        </FormGroup>
                      </Col>
                      <Col lg="12 d-none">
                        <FormGroup>
                          <label className="form-control-label" >HTS Tranport</label>
                          <div className="row">
                            <div className="chk_col">
                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  className="custom-control-input"
                                  id="hts_transport1"
                                  name="hts_transport_pickup"
                                  type="checkbox"
                                  checked={(this.state.hts_transport_pickup === 1)}
                                  onChange={this.onChangetrans}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="hts_transport1">
                                  Pick Up
                                </label>
                              </div>

                            </div>
                            <div className="chk_col">
                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  className="custom-control-input"
                                  id="hts_transport2"
                                  name="hts_transport_delivery"
                                  type="checkbox"
                                  checked={(this.state.hts_transport_delivery === 1)}
                                  onChange={this.onChangedelivery}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="hts_transport2">
                                  Delivery
                                </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Leackage Class</label>
                          <Input ref="leackage_class" className="form-control-alternative" maxLength="30" placeholder="Leackage Class" type="text" name="leackage_class" value={this.state.leackage_class} onChange={this.onChangeLeackclass} />
                          {/* <span className="error_cls">{this.state.errors["leackage_class"]}</span> */}
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Plant</label>
                          <Input ref="plant" className="form-control-alternative" placeholder="Plant" maxLength="30" type="text" name="plant" value={this.state.plant} onChange={this.onChangePlant} />
                          {/* <span className="error_cls">{this.state.errors["plant"]}</span> */}
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Quote To</label>
                          <Input ref="quote_to" className="form-control-alternative" placeholder="Quote To" maxLength="50" type="text" name="quote_to" value={this.state.quote_to} onChange={this.onChangeQuote} />
                          {/* <span className="error_cls">{this.state.errors["quote_to"]}</span> */}
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Valve Type </label>
                          <Input ref="valve_type" className="form-control-alternative" placeholder="Valve Type" maxLength="50" type="text" name="valve_type" value={this.state.valve_type} onChange={this.onChangeValveType} />
                          {/* <select ref="valve_type" className="form-control form-control-alternative" maxLength="30"  name="valve_type" value={this.state.valve_type} onChange={this.onChangeValveType}>
                            <option value="">Select</option>
                            <option value="Control Valve">Control Valve</option>
                            <option value="Mechanical Valve">Mechanical Valve</option>
                            <option value="On/Off Valve">On/Off Valve</option>
                          </select> */}
                          {/* <span className="error_cls">{this.state.errors["valve_type"]}</span> */}
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Valve Tag#</label>
                          <Input ref="valve_tag_no" className="form-control-alternative" maxLength="30" placeholder="Valve Tag#" name="valve_tag_no" value={this.state.valve_tag_no} type="text" onChange={this.onChangeValveTag} />
                          {/* <span className="error_cls">{this.state.errors["valve_tag_no"]}</span> */}
                        </FormGroup>
                      </Col>
                      {/* <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Valve Description</label>
                          <Input ref="valve_desc" className="form-control-alternative" maxLength="100" placeholder="Valve Description" type="text" name="valve_desc" value={this.state.valve_desc} onChange={this.onChangeValveDesc} />
                        </FormGroup>
                      </Col> */}
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Size / Rating <span className="text-danger"> *</span></label>
                          <Input ref="size" className="form-control-alternative" maxLength="30" placeholder="Size / Rating" type="text" name="size" value={this.state.size} onChange={this.onChangeSize} />
                          <span className="error_cls">{this.state.errors["size"]}</span>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">OGP# <span className="text-danger"> *</span></label>
                          <Input ref="ogp_no" className="form-control-alternative" placeholder="OGP#" maxLength="30" type="text" name="ogp_no" value={this.state.ogp_no} onChange={this.onChangeOGP} />
                          <span className="error_cls">{this.state.errors["ogp_no"]}</span>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">OGP Date <span className="text-danger"> *</span></label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            {/* <ReactDatetime
                              name="ogp_date"
                              inputProps={{
                                placeholder: "mm/dd/yyyy"
                              }}
                              timeFormat={false}
                              closeOnSelect={true}
                            /> */}
                            <Input className="form-control-alternative" ref="ogp_date" placeholder="dd-mmm-yyyy" type="Date" name="ogp_date" value={this.state.ogp_date} onChange={this.onChangeOGPDate} />
                          </InputGroup>
                          <span className="error_cls">{this.state.errors["ogp_date"]}</span>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Make / Model</label>
                          <Input className="form-control-alternative" placeholder="Make / Model" maxLength="30" ref="make_model" type="text" name="make_model" value={this.state.make_model} onChange={this.onChangeModel} />
                          {/* <span className="error_cls">{this.state.errors["make_model"]}</span> */}
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Delivery Time</label>
                          <Input className="form-control-alternative" placeholder="Delivery Time" ref="lead_time" type="text" value={this.state.lead_time} onChange={this.onChangeLead} />
                          {/* <span className="error_cls">{this.state.errors["lead_time"]}</span> */}
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Datasheet</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="data_chk1"
                                  name="datasheet"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.datasheet === 1)}
                                  onChange={this.onChangedatasheet}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="data_chk1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="data_chk2"
                                  name="datasheet"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.datasheet === 2)}
                                  onChange={this.onChangedatasheet}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="data_chk2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Fail Action </label>
                          <select className="form-control form-control-alternative" maxLength="1" value={this.state.fail_action} onChange={this.onchangeFail} name="fail_action">
                            <option value="1">Open</option>
                            <option value="2">Close</option>
                            <option value="3">On/Off</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="4" md="12" sm="12">
                        <FormGroup>
                          <label className="form-control-label">Upload </label><br></br>
                          <label htmlFor="myInput"><i className="ni ni-camera-compact cam_icon"></i></label>
                          <input
                            id="myInput"
                            style={{ display: 'none' }}
                            type={"file"}
                            capture="camera"
                            accept=".png, .jpg, .jpeg"
                            onChange={this.fileSelectedHandler}
                          />
                          <br></br>
                          {cust_img && (
                          <img src={this.state.imgSrc} className="preview_img" />
                          )}
                          {cust_img && (
                          <Input className="form-control-alternative text-center" readOnly placeholder="Description" name="imgdesc" type="text" value="As Received" />
                          )}
                          {/* <label className="file_lbl">{this.state.filename}</label> */}
                          {rmv_btn && (
                          <button type="button" title="Remove" className="remove" onClick={this.btn_rmv_cusotmer}><i className="ni ni-fat-remove"></i></button>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="12" className="text-center">
                        <FormGroup className="btn_formgrp">
                          <button type="button" className="btn btn-outline-danger btn_save" onClick={this.customerSubmit}>Save</button>
                          <button className="btn-icon btn btn-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                            <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                          </button>
                          <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                            <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                          </button>
                          
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Customer;
