import React from "react";

// reactstrap components
import {
    // Button,
    Card,
    CardHeader,
    //CardTitle,
    CardBody,
    FormGroup,
    //Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
class accessories_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            is_submit_click: 0,
            positioner: "",
            positioner_cali: "",
            sov: "",
            limit_sw: "",
            page_path: "Dashboard",
        };
        // this.handleValidation = this.handleValidation.bind(this);
        this.accessoriesSubmit = this.accessoriesSubmit.bind(this);
        this.GoToDashboard = this.GoToDashboard.bind(this);
        this.handleModuleChange = this.handleModuleChange.bind(this);

        this.onchangepositioner = this.onchangepositioner.bind(this);
        this.onchangepositioner_cali = this.onchangepositioner_cali.bind(this);
        this.onchangesov = this.onchangesov.bind(this);
        this.onchangelimit = this.onchangelimit.bind(this);

        this.inputValidation = this.inputValidation.bind(this);
        this.PreviousClick = this.PreviousClick.bind(this);
        this.NextClick = this.NextClick.bind(this);
        this.NextSaveClick = this.NextSaveClick.bind(this);
        this.PrevSaveClick = this.PrevSaveClick.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const jobData = {
                job_id: localStorage['job_id'],
                module_name: "job_accessories_detail"
            }
            axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    this.setState({
                        positioner: result.data.job_detail.acc_positioner,
                        positioner_cali: result.data.job_detail.acc_positioner_calibration,
                        sov: result.data.job_detail.acc_sov,
                        limit_sw: result.data.job_detail.acc_limit_switch,
                    });
                }).catch(function (error) {
                    //console.log(error);
                });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate () {
    }
    GoToDashboard() {
        this.props.history.push('./Dashboard');
    }
    PreviousClick(){
        this.props.history.push('./repaired_image');
    }
    NextClick(){
        this.props.history.push('./valve_testing');
    }
    NextSaveClick(){
        this.setState({
            page_path: "valve_testing",
        });
        this.accessoriesSubmit();
    }
    PrevSaveClick(){
        this.setState({
            page_path: "repaired_image",
        });
        this.accessoriesSubmit();
    }
    //select module
    handleModuleChange(e) {
        if (this.refs.select_module.value === 'Customer & Job') {
            this.props.history.push('./Customer');
        }
        else if (this.refs.select_module.value === 'Problems & Open Inspection') {
            this.props.history.push('./open_inspection');
        }
        else if (this.refs.select_module.value === 'Open Inspection Image') {
            this.props.history.push('./open_inspection_image');
        }
        else if (this.refs.select_module.value === 'General Service') {
            this.props.history.push('./general_services');
        }
        else if (this.refs.select_module.value === 'Valve Repair') {
            this.props.history.push('./valve_repair');
        }
        else if (this.refs.select_module.value === 'Actuator Repair') {
            this.props.history.push('./actuator_repair');
        }
        else if (this.refs.select_module.value === 'Accessories Detail') {
            this.props.history.push('./accessories_detail');
        }
        else if (this.refs.select_module.value === 'Valve Testing') {
            this.props.history.push('./valve_testing');
        }
        else if (this.refs.select_module.value === 'Repaired Images') {
            this.props.history.push('./repaired_image');
        }
        else if (this.refs.select_module.value === 'Dispatch') {
            this.props.history.push('./dispatch');
        }
    }
    handleValidation(e) {
        let errors = {};
        let formIsValid = true;

        if (!this.state.positioner) {
            formIsValid = false;
            errors["positioner"] = "Please select Positioner";
        }
        if (!this.state.positioner_cali) {
            formIsValid = false;
            errors["positioner_cali"] = "Please select Positioner Calibration";
        }
        if (!this.state.sov) {
            formIsValid = false;
            errors["sov"] = "Please select SOV";
        }
        if (!this.state.limit_sw) {
            formIsValid = false;
            errors["limit_sw"] = "Please select Limit Switch";
        }
        this.setState({ errors: errors });
        if (e == 1) {
            return formIsValid;
        }
    }
    inputValidation(e) {
        if (e === 1) {
            this.setState({
                positioner: this.state.positioner,
            }, () => { this.handleValidation(0) });
        }
    }
    onchangepositioner(e) {
        this.setState({
            positioner: e.target.value,
        }
        , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangepositioner_cali(e) {
        this.setState({
            positioner_cali: e.target.value,
        }
        , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangesov(e) {
        this.setState({
            sov: e.target.value,
        }
        , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangelimit(e) {
        this.setState({
            limit_sw: e.target.value,
        }
        , this.inputValidation(this.state.is_submit_click)
        );
    }
    accessoriesSubmit() {
        // e.preventDefault();
        this.setState({
            is_submit_click: 1
        });
        if (this.handleValidation(1)) {
            $("#root").addClass("disable_root");
            $("#loader").removeAttr("style").addClass("show_loader");
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const savejobData = {
                job_id: localStorage['job_id'],
                acc_positioner: this.state.positioner,
                acc_positioner_calibration: this.state.positioner_cali,
                acc_sov: this.state.sov,
                acc_limit_switch: this.state.limit_sw,
            }
            axios.post(server_config.API_BASE_URL+"updateAccessoriesDetail", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    if(this.state.page_path == "Dashboard"){
                        localStorage['form_submit_alert'] = "Accessories Detail Updated Successfully";
                    }
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                    // alert("Actuator Repair Detail Updated SuccessFully");
                    this.props.history.push("./"+this.state.page_path);
                }).catch(function (error) {
                    alert(error);
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                });
        } else {
            alert("Please fill up all mandatory fields.");
            window.scrollTo(0, 0);
        }
    }
    render() {
        return (
            <>
                <div className="header pt-5">
                    <Container fluid>
                        <div className="header-body">
                            <Card className="bg-secondary shadow border-0 mb-3">
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                            <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                                            <select ref="select_module" defaultValue="Accessories Detail" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                                                <option value="Customer & Job">Customer & Job</option>
                                                <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                                                <option value="Open Inspection Image">Open Inspection Image</option>
                                                <option value="General Service">General Service</option>
                                                <option value="Valve Repair">Valve Repair</option>
                                                <option value="Actuator Repair">Actuator Repair</option>
                                                <option value="Repaired Images">Repaired Images</option>
                                                <option value="Accessories Detail">Accessories Detail</option>
                                                <option value="Valve Testing">Valve Testing</option>
                                                <option value="Dispatch">Dispatch</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                </div>
                {/* Page content */}
                <Container fluid id="actuatorform">
                    <Row>
                        <Col lg="12">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-3">
                                    <div>
                                        <label className="mb-0 job_title">Accessories Detail</label>
                                        <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                                            <i className="ni ni-bold-left back_icon" />
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Positioner
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="positioner" id="positioner" maxLength="30" name="positioner" value={this.state.positioner} onChange={this.onchangepositioner}>
                                                        <option value="">Select</option>
                                                        <option value="Repair">Repair</option>
                                                        <option value="Not Repair">Not Repair</option>
                                                        <option value="Ok">Ok</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <span className="error_cls">{this.state.errors["positioner"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Positioner Calibration
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="positioner_cali" id="positioner_cali" maxLength="30" name="positioner_cali" value={this.state.positioner_cali} onChange={this.onchangepositioner_cali}>
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <span className="error_cls">{this.state.errors["positioner_cali"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">SOV
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="sov" id="sov" maxLength="30" name="sov" value={this.state.sov} onChange={this.onchangesov}>
                                                        <option value="">Select</option>
                                                        <option value="Tested">Tested</option>
                                                        <option value="Not Tested">Not Tested</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <span className="error_cls">{this.state.errors["sov"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Limit Switch
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="limit_sw" maxLength="30" id="limit_sw" name="limit_sw" value={this.state.limit_sw} onChange={this.onchangelimit}>
                                                        <option value="">Select</option>
                                                        <option value="Tested">Tested</option>
                                                        <option value="Not Tested">Not Tested</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <span className="error_cls">{this.state.errors["limit_sw"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" className="text-center">
                                                <FormGroup className="btn_formgrp">
                                                    <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                                                        <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                                                        <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                                                    </button>
                                                    <button type="button" className="btn btn-danger btn_save" onClick={this.accessoriesSubmit}>Save</button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                                                        <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                                                        <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                                                    </button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default accessories_detail;
