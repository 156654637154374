import Customer from "views/examples/Customer.jsx";
import open_inspection from "views/examples/open_inspection.jsx";
import general_services from "views/examples/general_services.jsx";
import valve_repair from "views/examples/valve_repair.jsx";
import actuator_repair from "views/examples/actuator_repair.jsx";
import accessories_detail from "views/examples/accessories_detail.jsx";
import valve_testing from "views/examples/valve_testing.jsx";
import dispatch from "views/examples/dispatch.jsx";
import open_inspection_image from "views/examples/open_inspection_image.jsx";
import repaired_image from "views/examples/repaired_image.jsx";
import Dashboard from "views/examples/Dashboard.jsx";
import page_not_found from "views/examples/page_not_found.jsx";
import Index from "views/Index.jsx";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin"
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-compass-04 text-red",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/",
    name: "Dashboard",
    icon: "ni ni-compass-04 text-red",
    component: Index,
    layout: "/"
  },
  {
    path: "/Customer",
    name: "Customer",
    icon: "ni ni-app text-red",
    component: Customer,
    layout: "/admin"
  },
  {
    path: "/open_inspection",
    name: "Open Inspection",
    icon: "ni ni-app text-red",
    component: open_inspection,
    layout: "/admin"
  },
  {
    path: "/open_inspection_image",
    name: "Open Inspection Image",
    icon: "ni ni-app text-red",
    component: open_inspection_image,
    layout: "/admin"
  },
  {
    path: "/general_services",
    name: "General Services",
    icon: "ni ni-app text-red",
    component: general_services,
    layout: "/admin"
  },
  {
    path: "/valve_repair",
    name: "Valve Repair",
    icon: "ni ni-app text-red",
    component: valve_repair,
    layout: "/admin"
  },
  {
    path: "/actuator_repair",
    name: "Actuator Repair",
    icon: "ni ni-app text-red",
    component: actuator_repair,
    layout: "/admin"
  },
  {
    path: "/repaired_image",
    name: "Repaired Image",
    icon: "ni ni-app text-red",
    component: repaired_image,
    layout: "/admin"
  },
  {
    path: "/accessories_detail",
    name: "Accessories Detail",
    icon: "ni ni-app text-red",
    component: accessories_detail,
    layout: "/admin"
  },
  {
    path: "/valve_testing",
    name: "Valve Testing",
    icon: "ni ni-app text-red",
    component: valve_testing,
    layout: "/admin"
  },
  {
    path: "/dispatch",
    name: "Dispatch",
    icon: "ni ni-app text-red",
    component: dispatch,
    layout: "/admin"
  },
  {
    path: "*",
    name: "Page Not Found",
    icon: "ni ni-app text-red",
    component: page_not_found,
    layout: "/"
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  
];
export default routes;
