import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  FormGroup,
  Input,
  CardFooter,
  Container,
  Row, CardBody, CardTitle, Col, Alert
} from "reactstrap";
import axios from 'axios';
import { server_config } from 'const';
import Select from 'react-select';
// core components
import DashHeader from "components/Headers/dash_header.jsx";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      is_submit_click: 0,
      jobdatalist: [],
      show_alert: false,
      show_alert_msg : "",
    };
	
    this.handleJobChange = this.handleJobChange.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);
    this.handleViewForm = this.handleViewForm.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

    this.handleInputChange  = this.handleInputChange .bind(this);
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["job_ref"]) {
      formIsValid = false;
      errors["job_ref"] = "Please Select Job Card.";
    }
    if (!fields["select_module"]) {
      formIsValid = false;
      errors["select_module"] = "Please Select Module.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  
  checkValidation() {
    setTimeout(function () { //Start the timer
      if (this.state.is_submit_click === 1) {
        this.handleValidation();
      }
    }.bind(this), 300)
  }
  componentDidMount() {
    this._isMounted = true;
		if (this._isMounted) {
			if(localStorage['form_submit_alert']){
			this.setState({
			  show_alert : true,
			  show_alert_msg : localStorage['form_submit_alert'],
			});
			localStorage['form_submit_alert']="";
		}
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onCloseAlert() {
    this.setState({
      show_alert: false,
    });
  }
 handleInputChange(e){
  if(e.length > 0){
      let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
      const savejobData = {
        search_parameter: e,
      } 
    axios.post(server_config.API_BASE_URL+"job_list", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
      .then(result => {
        this.setState({
          jobdatalist : result.data.job_list,
        });
      }).catch(function (error) {
        //console.log(error);
      });
  }
 }
  handleJobChange(field, e) {
    // console.log(e.label, e.value)
    let fields = this.state.fields;
    fields[field] = e.value;
    this.setState({
      fields,
    });
    localStorage['job_id'] = e.value;
    localStorage['job_name'] = e.label;
    this.checkValidation();
  }
  handleModuleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields
    });
    this.checkValidation();
  }
  handleViewForm(e) {
    e.preventDefault();
    this.setState({
      is_submit_click: 1
    });
    this.checkValidation();

    if (this.refs.select_module.value == 'Customer & Job' && this.refs.job_ref.value !== '') {
      this.props.history.push('./Customer');
    }
    else if (this.refs.select_module.value == 'Problems & Open Inspection' && this.refs.job_ref.value !== '') {
      this.props.history.push('./open_inspection');
    }
    else if (this.refs.select_module.value == 'Open Inspection Image' && this.refs.job_ref.value !== '') {
      this.props.history.push('./open_inspection_image');
    }
    else if (this.refs.select_module.value == 'General Service' && this.refs.job_ref.value !== '') {
      this.props.history.push('./general_services');
    }
    else if (this.refs.select_module.value == 'Valve Repair' && this.refs.job_ref.value !== '') {
      this.props.history.push('./valve_repair');
    }
    else if (this.refs.select_module.value == 'Actuator Repair' && this.refs.job_ref.value !== '') {
      this.props.history.push('./actuator_repair');
    }
    else if (this.refs.select_module.value == 'Accessories Detail' && this.refs.job_ref.value !== '') {
      this.props.history.push('./accessories_detail');
    }
    else if (this.refs.select_module.value == 'Valve Testing' && this.refs.job_ref.value !== '') {
      this.props.history.push('./valve_testing');
    }
    else if (this.refs.select_module.value == 'Repaired Images' && this.refs.job_ref.value !== '') {
      this.props.history.push('./repaired_image');
    }
    else if (this.refs.select_module.value == 'Dispatch' && this.refs.job_ref.value !== '') {
      this.props.history.push('./dispatch');
    }

  }
  render() {
    const { show_alert } = this.state;
    return (
      <>
        <DashHeader />
        {/* Page content */}
        <Container className="" fluid>
          <Row>
            <Col lg="12" xl="12">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-3">
                  <div>
                    <p className="mb-0 job_title">Job</p>
                  </div>
                </CardHeader>
                <CardBody>
                  {show_alert && (
                    <Alert className="alert_card alert-success">
                      <button type="button" className="close" onClick={this.onCloseAlert}>
                        <span aria-hidden="true">×</span>
                      </button>
                      <strong>Success ! </strong>{this.state.show_alert_msg}
                    </Alert>
                  )}
                  <form>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Search Job<span className="text-danger"> *</span></label>
                          <Select
                          ref="job_ref"
                          getOptionLabel={e => e.label}
                          getOptionValue={e => e.value}
                          options={this.state.jobdatalist}
                          onInputChange={this.handleInputChange.bind(this)}
                          onChange={this.handleJobChange.bind(this,"job_ref")}
                          placeholder="Enter two or more characters"
                          />
                          <span className="error_cls">{this.state.errors["job_ref"]}</span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Module<span className="text-danger"> *</span></label>
                          <select ref="select_module" className="form-control form-control-alternative" onChange={this.handleModuleChange.bind(this, "select_module")}>
                            <option value="">Select</option>
                            <option value="Customer & Job">Customer & Job</option>
                            <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                            <option value="Open Inspection Image">Open Inspection Image</option>
                            <option value="General Service">General Service</option>
                            <option value="Valve Repair">Valve Repair</option>
                            <option value="Actuator Repair">Actuator Repair</option>
                            <option value="Repaired Images">Repaired Images</option>
                            <option value="Accessories Detail">Accessories Detail</option>
                            <option value="Valve Testing">Valve Testing</option>
                            <option value="Dispatch">Dispatch</option>
                          </select>
                          <span className="error_cls">{this.state.errors["select_module"]}</span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="text-center">
                        <FormGroup>
                          <button type="submit" className="btn btn-primary" onClick={this.handleViewForm}>View Form</button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
