import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  //CardTitle,
  CardBody,
  FormGroup,
  //Form,
  //Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
class general_services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      dismantling: 1,
      assesment: 2,
      cleaning: 1,
      actuator_service: 2,
      painting: 2,
      operation_check: 2,
      handwheel_assy: 2,
      page_path: "Dashboard",
    };
    this.GoToDashboard = this.GoToDashboard.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);
    this.onChangedismantling = this.onChangedismantling.bind(this);
    this.onChangeassesment = this.onChangeassesment.bind(this);
    this.onChangecleaning = this.onChangecleaning.bind(this);
    this.onChangeactuator = this.onChangeactuator.bind(this);
    this.onChangepainting = this.onChangepainting.bind(this);
    this.onChangeoperation = this.onChangeoperation.bind(this);
    this.onChangehandwheel = this.onChangehandwheel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.PreviousClick = this.PreviousClick.bind(this);
    this.NextClick = this.NextClick.bind(this);
    this.NextSaveClick = this.NextSaveClick.bind(this);
    this.PrevSaveClick = this.PrevSaveClick.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
      const jobData = {
        job_id: localStorage['job_id'],
        module_name: "job_general_service"
      }
      axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
        .then(result => {
          this.setState({
            dismantling: result.data.job_detail.dismantling,
            assesment: result.data.job_detail.assesment,
            cleaning: result.data.job_detail.cleaning,
            actuator_service: result.data.job_detail.actuator_service,
            painting: result.data.job_detail.painting,
            operation_check: result.data.job_detail.operation_check,
            handwheel_assy: result.data.job_detail.handwheel_assy,
          });
        }).catch(function (error) {
          //console.log(error);
        });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  GoToDashboard() {
    this.props.history.push('./Dashboard');
  }
  PreviousClick(){
    this.props.history.push('./open_inspection_image');
  }
  NextClick(){
    this.props.history.push('./valve_repair');
  }
  NextSaveClick(){
    this.setState({
      page_path: "valve_repair",
    });
    this.handleSubmit();
  }
  PrevSaveClick(){
    this.setState({
      page_path: "open_inspection_image",
    });
    this.handleSubmit();
  }
  onChangedismantling(e) {
    this.setState({
      dismantling: parseInt(e.target.value),
    });
  }
  onChangeassesment(e) {
    this.setState({
      assesment: parseInt(e.target.value),
    });
  }
  onChangecleaning(e) {
    this.setState({
      cleaning: parseInt(e.target.value),
    });
  }
  onChangeactuator(e) {
    this.setState({
      actuator_service: parseInt(e.target.value),
    });
  }
  onChangepainting(e) {
    this.setState({
      painting: parseInt(e.target.value),
    });
  }
  onChangeoperation(e) {
    this.setState({
      operation_check: parseInt(e.target.value),
    });
  }
  onChangehandwheel(e) {
    this.setState({
      handwheel_assy: parseInt(e.target.value),
    });
  }
  //select module
  handleModuleChange(e) {
    if (this.refs.select_module.value === 'Customer & Job') {
      this.props.history.push('./Customer');
    }
    else if (this.refs.select_module.value === 'Problems & Open Inspection') {
      this.props.history.push('./open_inspection');
    }
    else if (this.refs.select_module.value === 'Open Inspection Image') {
      this.props.history.push('./open_inspection_image');
    }
    else if (this.refs.select_module.value === 'General Service') {
      this.props.history.push('./general_services');
    }
    else if (this.refs.select_module.value === 'Valve Repair') {
      this.props.history.push('./valve_repair');
    }
    else if (this.refs.select_module.value === 'Actuator Repair') {
      this.props.history.push('./actuator_repair');
    }
    else if (this.refs.select_module.value === 'Accessories Detail') {
      this.props.history.push('./accessories_detail');
    }
    else if (this.refs.select_module.value === 'Valve Testing') {
      this.props.history.push('./valve_testing');
    }
    else if (this.refs.select_module.value === 'Repaired Images') {
      this.props.history.push('./repaired_image');
    }
    else if (this.refs.select_module.value === 'Dispatch') {
      this.props.history.push('./dispatch');
    }
  }

  handleSubmit() {
    // e.preventDefault();
    $("#root").addClass("disable_root");
    $("#loader").removeAttr("style").addClass("show_loader");
    let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
    const savejobData = {
      job_id: localStorage['job_id'],
      dismantling: this.state.dismantling,
      assesment: this.state.assesment,
      cleaning: this.state.cleaning,
      actuator_service: this.state.actuator_service,
      painting: this.state.painting,
      operation_check: this.state.operation_check,
      handwheel_assy: this.state.handwheel_assy,
    }
    axios.post(server_config.API_BASE_URL+"updateGenralService", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
      .then(result => {
        if(this.state.page_path == "Dashboard"){
          localStorage['form_submit_alert'] = "General Services Updated Successfully";
        }
        $("#root").removeClass("disable_root");
        $("#loader").removeClass("show_loader").css("display", "none");
        // alert("General Services Updated SuccessFully");
        this.props.history.push("./"+this.state.page_path);
      }).catch(function (error) {
        alert(error);
        $("#root").removeClass("disable_root");
        $("#loader").removeClass("show_loader").css("display", "none");
      });
  }
  render() {
    return (
      <>
        <div className="header pt-5">
          <Container fluid>
            <div className="header-body">
              <Card className="bg-secondary shadow border-0 mb-3">
                <CardBody>
                  <Row>
                    <Col lg="12">
                    <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                      <select ref="select_module" defaultValue="General Service" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                        <option value="Customer & Job">Customer & Job</option>
                        <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                        <option value="Open Inspection Image">Open Inspection Image</option>
                        <option value="General Service">General Service</option>
                        <option value="Valve Repair">Valve Repair</option>
                        <option value="Actuator Repair">Actuator Repair</option>
                        <option value="Repaired Images">Repaired Images</option>
                        <option value="Accessories Detail">Accessories Detail</option>
                        <option value="Valve Testing">Valve Testing</option>
                        <option value="Dispatch">Dispatch</option>
                      </select>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-3">
                  <div>
                    <label className="mb-0 job_title">General Services</label>
                    <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                      <i className="ni ni-bold-left back_icon" />
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Dismantling</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="dismantling_rd1"
                                  name="dismantling"
                                  checked={(this.state.dismantling === 1)}
                                  type="radio"
                                  value="1"
                                  onChange={this.onChangedismantling}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="dismantling_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="dismantling_rd2"
                                  name="dismantling"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.dismantling === 2)}
                                  onChange={this.onChangedismantling}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="dismantling_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Assessment</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="assesment_rd1"
                                  name="assesment"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.assesment === 1)}
                                  onChange={this.onChangeassesment}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="assesment_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="assesment_rd2"
                                  name="assesment"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.assesment === 2)}
                                  onChange={this.onChangeassesment}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="assesment_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Cleaning</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="cleaning_rd1"
                                  name="cleaning"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.cleaning === 1)}
                                  onChange={this.onChangecleaning}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="cleaning_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="cleaning_rd2"
                                  name="cleaning"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.cleaning === 2)}
                                  onChange={this.onChangecleaning}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="cleaning_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Actuator Service</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="actuator_service_rd1"
                                  name="actuator_service"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.actuator_service === 1)}
                                  onChange={this.onChangeactuator}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="actuator_service_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="actuator_service_rd2"
                                  name="actuator_service"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.actuator_service === 2)}
                                  onChange={this.onChangeactuator}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="actuator_service_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Painting</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="painting_rd1"
                                  name="painting"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.painting === 1)}
                                  onChange={this.onChangepainting}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="painting_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="painting_rd2"
                                  name="painting"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.painting === 2)}
                                  onChange={this.onChangepainting}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="painting_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Operation Check</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="operation_check_rd1"
                                  name="operation_check"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.operation_check === 1)}
                                  onChange={this.onChangeoperation}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="operation_check_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="operation_check_rd2"
                                  name="operation_check"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.operation_check === 2)}
                                  onChange={this.onChangeoperation}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="operation_check_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Handwheel Assy</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="handwheel_assy_rd1"
                                  name="handwheel_assy"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.handwheel_assy === 1)}
                                  onChange={this.onChangehandwheel}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="handwheel_assy_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="handwheel_assy_rd2"
                                  name="handwheel_assy"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.handwheel_assy === 2)}
                                  onChange={this.onChangehandwheel}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="handwheel_assy_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12" className="text-center">
                        <FormGroup className="btn_formgrp">
                          <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                            <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                          </button>
                          <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                            <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                          </button>
                          <button type="button" className="btn btn-danger btn_save" onClick={this.handleSubmit}>Save</button>
                          <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                            <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                          </button>
                          <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                            <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                          </button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default general_services;
