import React from "react";

// reactstrap components
import {
  //Button,
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.GoToDashboard = this.GoToDashboard.bind(this);
  }
  GoToDashboard() {
    this.props.history.push('./admin/Dashboard');
  }

  render() {
    return (
      <>
        <div className="header pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              <Card className="bg-secondary shadow border-0 mb-3">
              </Card>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container fluid>
          <Row>
              <Col lg="12">
                <div className="text-center">
                    <h2 className="mt-2 mb-4">Welcome To Hi Tech Services</h2>
                    <button type="button" class="btn-icon btn btn-danger go_btn mt-1" onClick={this.GoToDashboard}><span class="btn-inner--icon go_to_icon"><i class="ni ni-bold-left"></i></span><span class="btn-inner--text">Go To Dashboard</span></button>
                </div>
              </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
