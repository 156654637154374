import React from "react";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  //CardTitle,
  CardBody,
  FormGroup,
  //Form,
  Input,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
import SignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-canvas';

class open_inspection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      open_inspection: "",
      leackage: 1,
      gland_leak: 1,
      actuator_problem: 1,
      hanwheel_assy: 1,
      positioner_problem: 1,
      EnggtrimmedDataURL: null,
      SupertrimmedDataURL: null,
      is_engg_sign: 0,
      is_super_sign: 0,
      supervisor_name : "",
      engineer_name : "",
      technician_name : "",      
      page_path: "Dashboard",
    };
    this.GoToDashboard = this.GoToDashboard.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);
    this.onChangeleackage = this.onChangeleackage.bind(this);
    this.onChangeGlandLeak = this.onChangeGlandLeak.bind(this);
    this.onChangeActuator = this.onChangeActuator.bind(this);
    this.onChangeHandwheel = this.onChangeHandwheel.bind(this);
    this.onChangePositioner = this.onChangePositioner.bind(this);
    this.onChangeInspection = this.onChangeInspection.bind(this);
    this.handlesubmit = this.handlesubmit.bind(this);
    this.PreviousClick = this.PreviousClick.bind(this);
    this.NextClick = this.NextClick.bind(this);
    this.NextSaveClick = this.NextSaveClick.bind(this);
    this.PrevSaveClick = this.PrevSaveClick.bind(this);
    this.superclear = this.superclear.bind(this);
    this.enggclear = this.enggclear.bind(this);
    this.saveEnggsign = this.saveEnggsign.bind(this);
    this.saveSupersign = this.saveSupersign.bind(this);
    this.Supmodal = this.Supmodal.bind(this);
    this.closeSupmodal = this.closeSupmodal.bind(this);
    this.enggmodal = this.enggmodal.bind(this);
    this.closeengmodal = this.closeengmodal.bind(this);
    this.onChangesuptxt = this.onChangesuptxt.bind(this);
    this.onChangeengtxt = this.onChangeengtxt.bind(this);
    this.onChangetechtxt = this.onChangetechtxt.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
      const jobData = {
        job_id: localStorage['job_id'],
        module_name: "job_open_inspection"
      }
      
      axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
        .then(result => {
          this.setState({
            open_inspection: result.data.job_detail.open_inspection,
            o_technician_name: result.data.job_detail.o_technician_name,
            leackage: result.data.job_detail.leackage,
            gland_leak: result.data.job_detail.gland_leak,
            actuator_problem: result.data.job_detail.actuator_problem,
            hanwheel_assy: result.data.job_detail.hanwheel_assy,
            positioner_problem: result.data.job_detail.positioner_problem,
            technician_name: result.data.job_detail.o_technician_name,
            EnggtrimmedDataURL: (result.data.job_detail.o_engineer_sign != '' && result.data.job_detail.o_engineer_sign != null) ? result.data.base_url + "/" + result.data.job_detail.o_engineer_sign : null,
            SupertrimmedDataURL: (result.data.job_detail.o_supervisor_sign != '' && result.data.job_detail.o_supervisor_sign != null) ? result.data.base_url + "/" + result.data.job_detail.o_supervisor_sign : null,
            supervisor_name: result.data.job_detail.o_supervisor_name,
            engineer_name: result.data.job_detail.o_engineer_name,
          });
        }).catch(function (error) {
          //console.log(error);
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  GoToDashboard() {
    this.props.history.push('./Dashboard');
  }
  PreviousClick(){
    this.props.history.push('./Customer');
  }
  NextClick(){
    this.props.history.push('./open_inspection_image');
  }
  NextSaveClick(){
    this.setState({
      page_path: "open_inspection_image",
    });
    this.handlesubmit();
  }
  PrevSaveClick(){
    this.setState({
      page_path: "Customer",
    });
    this.handlesubmit();
  }
  onChangeleackage(e) {
    this.setState({
      leackage: parseInt(e.target.value),
    });
  }
  onChangeGlandLeak(e) {
    this.setState({
      gland_leak: parseInt(e.target.value),
    });
  }
  onChangeActuator(e) {
    this.setState({
      actuator_problem: parseInt(e.target.value),
    });
  }
  onChangeHandwheel(e) {
    this.setState({
      hanwheel_assy: parseInt(e.target.value),
    });
  }
  onChangePositioner(e) {
    this.setState({
      positioner_problem: parseInt(e.target.value),
    });
  }
  onChangeInspection(e) {
    this.setState({
      open_inspection: e.target.value,
    });
  }
  Supmodal() { //open tech sign Modal
    this.setState({
        supmodal: true
    });
  }
  closeSupmodal() {
      this.setState({
          supmodal: false
      });
  }
  superclear() {
      this.supPad.clear();
      this.setState({
          SupertrimmedDataURL: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=',
          is_super_sign: 0
      });
  }
  enggmodal() { //open tech sign Modal
      this.setState({
          engmodal: true
      });
  }
  closeengmodal() {
      this.setState({
          engmodal: false
      });
  }
  enggclear() {
      this.enggPad.clear();
      this.setState({
          EnggtrimmedDataURL: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=',
          is_engg_sign: 0
      });
  }

  saveSupersign() {
      this.setState({
          SupertrimmedDataURL: this.supPad.getTrimmedCanvas().toDataURL('image/png'),
          is_super_sign: 1,
          supmodal: false,
      });
  }

  saveEnggsign() {
      this.setState({
          EnggtrimmedDataURL: this.enggPad.getTrimmedCanvas().toDataURL('image/png'),
          is_engg_sign: 1,
          engmodal: false,
      });
  }

  onChangesuptxt(e) {
      this.setState({
          supervisor_name: e.target.value,
      });
  }
  onChangeengtxt(e) {
      this.setState({
          engineer_name: e.target.value,
      });
  }
  onChangetechtxt(e) {
    this.setState({
        technician_name: e.target.value,
    });
}
  handleModuleChange(e) {
    if (this.refs.select_module.value === 'Customer & Job') {
      this.props.history.push('./Customer');
    }
    else if (this.refs.select_module.value === 'Problems & Open Inspection') {
      this.props.history.push('./open_inspection');
    }
    else if (this.refs.select_module.value === 'Open Inspection Image') {
      this.props.history.push('./open_inspection_image');
    }
    else if (this.refs.select_module.value === 'General Service') {
      this.props.history.push('./general_services');
    }
    else if (this.refs.select_module.value === 'Valve Repair') {
      this.props.history.push('./valve_repair');
    }
    else if (this.refs.select_module.value === 'Actuator Repair') {
      this.props.history.push('./actuator_repair');
    }
    else if (this.refs.select_module.value === 'Accessories Detail') {
      this.props.history.push('./accessories_detail');
    }
    else if (this.refs.select_module.value === 'Valve Testing') {
      this.props.history.push('./valve_testing');
    }
    else if (this.refs.select_module.value === 'Repaired Images') {
      this.props.history.push('./repaired_image');
    }
    else if (this.refs.select_module.value === 'Dispatch') {
      this.props.history.push('./dispatch');
    }
  }
  handlesubmit() {
    // e.preventDefault();
    $("#root").addClass("disable_root");
    $("#loader").removeAttr("style").addClass("show_loader");
    let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
    const savejobData = {
      job_id: localStorage['job_id'],
      open_inspection: this.state.open_inspection,
      leackage: this.state.leackage,
      gland_leak: this.state.gland_leak,
      actuator_problem: this.state.actuator_problem,
      hanwheel_assy: this.state.hanwheel_assy,
      positioner_problem: this.state.positioner_problem,
      o_engineer_sign: this.state.EnggtrimmedDataURL,
      o_supervisor_sign: this.state.SupertrimmedDataURL,
      o_is_engg_sign: this.state.is_engg_sign,
      o_is_super_sign: this.state.is_super_sign,
      o_supervisor_name: this.state.supervisor_name,
      o_engineer_name: this.state.engineer_name,
      o_technician_name: this.state.technician_name,
    }
    
    axios.post(server_config.API_BASE_URL+"updateOpenInspection", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
      .then(result => {
        if(this.state.page_path == "Dashboard"){
          localStorage['form_submit_alert'] = "Open Inspection Details Updated Successfully";
        }
        $("#root").removeClass("disable_root");
        $("#loader").removeClass("show_loader").css("display", "none");
        // alert("Open Inspection Details Updated SuccessFully");
        this.props.history.push("./"+this.state.page_path);
      }).catch(function (error) {
        alert(error);
        $("#root").removeClass("disable_root");
        $("#loader").removeClass("show_loader").css("display", "none");
      });
  }
  render() {
    let { SupertrimmedDataURL, EnggtrimmedDataURL } = this.state;
    return (
      <>
        <div className="header pt-5">
          <Container fluid>
            <div className="header-body">
              <Card className="bg-secondary shadow border-0 mb-3">
                <CardBody>
                  <Row>
                    <Col lg="12">
                    <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                      <select ref="select_module" defaultValue="Problems & Open Inspection" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                        <option value="Customer & Job">Customer & Job</option>
                        <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                        <option value="Open Inspection Image">Open Inspection Image</option>
                        <option value="General Service">General Service</option>
                        <option value="Valve Repair">Valve Repair</option>
                        <option value="Actuator Repair">Actuator Repair</option>
                        <option value="Repaired Images">Repaired Images</option>
                        <option value="Accessories Detail">Accessories Detail</option>
                        <option value="Valve Testing">Valve Testing</option>
                        <option value="Dispatch">Dispatch</option>
                      </select>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-3">
                  <div>
                    <label className="mb-0 job_title">Open Inspection</label>
                    <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                      <i className="ni ni-bold-left back_icon" />
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Leackage / Passing</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  checked={(this.state.leackage === 1)}
                                  id="leackage_rd1"
                                  name="leackage"
                                  type="radio"
                                  value="1"
                                  onChange={this.onChangeleackage}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="leackage_rd1"> Yes </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  checked={(this.state.leackage === 2)}
                                  id="leackage_rd2"
                                  name="leackage"
                                  type="radio"
                                  value="2"
                                  onChange={this.onChangeleackage}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="leackage_rd2"> No </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  checked={(this.state.leackage === 3)}
                                  id="leackage_rd3"
                                  name="leackage"
                                  type="radio"
                                  value="3"
                                  onChange={this.onChangeleackage}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="leackage_rd3"> N/A </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Gland Leak</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="gland_rd1"
                                  name="gland_leak"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.gland_leak === 1)}
                                  onChange={this.onChangeGlandLeak}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="gland_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="gland_rd2"
                                  name="gland_leak"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.gland_leak === 2)}
                                  onChange={this.onChangeGlandLeak}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="gland_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="gland_rd3"
                                  name="gland_leak"
                                  type="radio"
                                  value="3"
                                  checked={(this.state.gland_leak === 3)}
                                  onChange={this.onChangeGlandLeak}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="gland_rd3">
                                  N/A
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Actuator Problem</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="actuator_rd1"
                                  name="actuator_problem"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.actuator_problem === 1)}
                                  onChange={this.onChangeActuator}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="actuator_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="actuator_rd2"
                                  name="actuator_problem"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.actuator_problem === 2)}
                                  onChange={this.onChangeActuator}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="actuator_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="actuator_rd3"
                                  name="actuator_problem"
                                  type="radio"
                                  value="3"
                                  checked={(this.state.actuator_problem === 3)}
                                  onChange={this.onChangeActuator}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="actuator_rd3">
                                  N/A
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Handwheel Assy</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="hanwheel_assy_rd1"
                                  name="hanwheel_assy"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.hanwheel_assy === 1)}
                                  onChange={this.onChangeHandwheel}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="hanwheel_assy_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="hanwheel_assy_rd2"
                                  name="hanwheel_assy"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.hanwheel_assy === 2)}
                                  onChange={this.onChangeHandwheel}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="hanwheel_assy_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="hanwheel_assy_rd3"
                                  name="hanwheel_assy"
                                  type="radio"
                                  value="3"
                                  checked={(this.state.hanwheel_assy === 3)}
                                  onChange={this.onChangeHandwheel}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="hanwheel_assy_rd3">
                                  N/A
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Positioner Problem</label>
                          <div className="row">
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="positioner_problem_rd1"
                                  name="positioner_problem"
                                  type="radio"
                                  value="1"
                                  checked={(this.state.positioner_problem === 1)}
                                  onChange={this.onChangePositioner}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="positioner_problem_rd1">
                                  Yes
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="positioner_problem_rd2"
                                  name="positioner_problem"
                                  type="radio"
                                  value="2"
                                  checked={(this.state.positioner_problem === 2)}
                                  onChange={this.onChangePositioner}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="positioner_problem_rd2">
                                  No
                          </label>
                              </div>
                            </div>
                            <div className="col_div">
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="positioner_problem_rd3"
                                  name="positioner_problem"
                                  type="radio"
                                  value="3"
                                  checked={(this.state.positioner_problem === 3)}
                                  onChange={this.onChangePositioner}
                                  maxLength="1"
                                />
                                <label className="custom-control-label" htmlFor="positioner_problem_rd3">
                                  N/A
                          </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Open Inspection</label>
                          <Input className="form-control-alternative" rows="2" maxLength="255" type="textarea" value={this.state.open_inspection} name="open_inspection" onChange={this.onChangeInspection} />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                              <label className="form-control-label">Technician Name</label>
                              <Input className="form-control-alternative" placeholder="Technician Name" ref="technician_name" value={this.state.technician_name} name="technician_name" type="text" onChange={this.onChangetechtxt} maxLength="50"/>
                        </FormGroup>
                      </Col>
                      <Col lg="6" md="6" sm="12" className="mt-3">
                          <FormGroup className="mb-1">
                              <label className="form-control-label">Supervisor Sign</label>
                              <button type="button" onClick={this.Supmodal} className="btn sign_clearbtn">Sign</button>
                              
                              {SupertrimmedDataURL ? (
                                  <img
                                      src={SupertrimmedDataURL}
                                      className="signimg"
                                  />
                              ) : null}
                          </FormGroup>
                          <FormGroup>
                              <Input className="form-control-alternative" placeholder="Supervisor Name" ref="supervisor_name" maxLength="50" value={this.state.supervisor_name} name="supervisor_name" type="text" onChange={this.onChangesuptxt} />
                              <span className="error_cls">{this.state.errors["supervisor_name"]}</span>
                          </FormGroup>
                      </Col>
                      <Col lg="6" md="6" sm="12" className="mt-3">
                          <FormGroup className="mb-1">
                              <label className="form-control-label">Engineer Sign</label>
                              <button type="button" onClick={this.enggmodal} className="btn sign_clearbtn">Sign</button>
                              
                              {EnggtrimmedDataURL ? (
                                  <img
                                      src={EnggtrimmedDataURL}
                                      className="signimg"
                                  />
                              ) : null}
                          </FormGroup>
                          <FormGroup>
                              <Input className="form-control-alternative" placeholder="Engineer Name" ref="engineer_name" name="engineer_name" maxLength="50" value={this.state.engineer_name} type="text" onChange={this.onChangeengtxt} />
                              <span className="error_cls">{this.state.errors["engineer_name"]}</span>
                          </FormGroup>
                      </Col>
                      <Col lg="12" className="text-center">
                        <FormGroup className="btn_formgrp">
                          <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                            <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                          </button>
                          <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                            <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                          </button>
                          <button type="button" className="btn btn-danger btn_save" onClick={this.handlesubmit}>Save</button>
                          <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                            <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                          </button>
                          <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                            <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                          </button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.supmodal}>
          <form>
          <div className="modal-header">
              <h3 className="modal-title">Supervisor Sign</h3>
              <button type="button" onClick={this.closeSupmodal} className="btn fa fa-times modal_close"></button>
          </div>
          <div className="modal-body">
              <SignatureCanvas
                  ref={(ref) => { this.supPad = ref }}
                  canvasProps={{ className: 'sigCanvas signPad' }} />
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={this.saveSupersign}>
                  Save
                  </button>
              <button type="button" className="btn btn-secondary" onClick={this.superclear}>
                  Clear
                  </button>
          </div>
          </form>
      </Modal>
      <Modal isOpen={this.state.engmodal}>
          <form>
          <div className="modal-header">
              <h3 className="modal-title">Engineer Sign</h3>
              <button type="button" onClick={this.closeengmodal} className="fa fa-times btn modal_close"></button>
          </div>
          <div className="modal-body">
              <SignaturePad
                  ref={(ref) => { this.enggPad = ref }}
                  canvasProps={{ className: 'sigCanvas signPad' }} />
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={this.saveEnggsign}>
                  Save
                  </button>
              <button type="button" className="btn btn-secondary" onClick={this.enggclear}>
                  Clear
                  </button>
          </div>
          </form>
      </Modal>
      </>
    );
  }
}

export default open_inspection;
