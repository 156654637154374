import React from "react";

// reactstrap components
import {
    // Button,
    Card,
    CardHeader,
    //CardTitle,
    CardBody,
    FormGroup,
    //Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import axios from 'axios';
import $ from 'jquery';
import { server_config } from 'const';
class actuator_repair extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // errors: {},
            // is_submit_click: 0,
            act_size: "",
            actsize_notes: "",
            diaphragm: "",
            diaphragm_notes: "",
            fastners: "",
            fastners_notes: "",
            soft_parts: "",
            soft_parts_notes: "",
            stem: "",
            stem_notes: "",
            bushing: "",
            bushing_notes: "",
            spring: "",
            spring_notes: "",
            other: "",
            page_path: "Dashboard",
            is_actuator_applicable: 1,
        };
        // this.handleValidation = this.handleValidation.bind(this);
        this.actuatorRepairSubmit = this.actuatorRepairSubmit.bind(this);
        this.GoToDashboard = this.GoToDashboard.bind(this);
        this.handleModuleChange = this.handleModuleChange.bind(this);
        this.onchangeDiaphragmNote = this.onchangeDiaphragmNote.bind(this);
        this.onchangeFastnerNote = this.onchangeFastnerNote.bind(this);
        this.onchangeSoftPartNote = this.onchangeSoftPartNote.bind(this);
        this.onchangeStemNote = this.onchangeStemNote.bind(this);
        this.onchangeBushingNote = this.onchangeBushingNote.bind(this);
        this.onchangeSpringNote = this.onchangeSpringNote.bind(this);
        this.onchangeother = this.onchangeother.bind(this);
        this.onchangeDiaphragm = this.onchangeDiaphragm.bind(this);
        this.onchangeFastner = this.onchangeFastner.bind(this);
        this.onchangeSoftPart = this.onchangeSoftPart.bind(this);
        this.onchangeStem = this.onchangeStem.bind(this);
        this.onchangeBushing = this.onchangeBushing.bind(this);
        this.onchangeSpring = this.onchangeSpring.bind(this);
        // this.inputValidation = this.inputValidation.bind(this);
        this.PreviousClick = this.PreviousClick.bind(this);
        this.NextClick = this.NextClick.bind(this);
        this.NextSaveClick = this.NextSaveClick.bind(this);
        this.PrevSaveClick = this.PrevSaveClick.bind(this);
        this.onChangeactrepair_applicable = this.onChangeactrepair_applicable.bind(this);
        this.onchangeActsizeNote = this.onchangeActsizeNote.bind(this);
        this.onchangeActSize = this.onchangeActSize.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const jobData = {
                job_id: localStorage['job_id'],
                module_name: "job_actuator_parts_replaced"
            }
            axios.post(server_config.API_BASE_URL+"getJobModuleDetail", jobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    this.setState({
                        diaphragm: result.data.job_detail.diaphragm,
                        diaphragm_notes: result.data.job_detail.diaphragm_notes,
                        act_size: result.data.job_detail.actuator_size,
                        actsize_notes: result.data.job_detail.actuator_size_notes,
                        fastners: result.data.job_detail.fastners,
                        fastners_notes: result.data.job_detail.fastners_notes,
                        soft_parts: result.data.job_detail.soft_parts,
                        soft_parts_notes: result.data.job_detail.soft_parts_notes,
                        stem: result.data.job_detail.stem,
                        stem_notes: result.data.job_detail.stem_notes,
                        bushing: result.data.job_detail.bushing,
                        bushing_notes: result.data.job_detail.bushing_notes,
                        spring: result.data.job_detail.spring,
                        spring_notes: result.data.job_detail.spring_notes,
                        other: result.data.job_detail.other,

                        is_actuator_applicable: result.data.job_detail.is_actuator_applicable,
                    });
                }).catch(function (error) {
                    //console.log(error);
                });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate () {
        if (document.querySelector('input[name=is_actuator_applicable]:checked').value == 2) {
            // $("#actuatorform select").val('N/A');
            // $("#actuatorform select").attr('disabled','disabled');
            $("#diaphragm,#fastners,#soft_parts,#stem,#bushing,#spring").val('N/A').attr('disabled','disabled');
            $("#actuatorform input").not("[name=actsize_notes]").attr('readonly','true');
            
        }else{
            $("#actuatorform select").removeAttr('disabled');
            $("#actuatorform input").removeAttr('readonly');
        }
    }
    GoToDashboard() {
        this.props.history.push('./Dashboard');
    }
    PreviousClick(){
        this.props.history.push('./valve_repair');
    }
    NextClick(){
        this.props.history.push('./repaired_image');
    }
    NextSaveClick(){
        this.setState({
            page_path: "repaired_image",
        });
        this.actuatorRepairSubmit();
    }
    PrevSaveClick(){
        this.setState({
            page_path: "valve_repair",
        });
        this.actuatorRepairSubmit();
    }
    //select module
    handleModuleChange(e) {
        if (this.refs.select_module.value === 'Customer & Job') {
            this.props.history.push('./Customer');
        }
        else if (this.refs.select_module.value === 'Problems & Open Inspection') {
            this.props.history.push('./open_inspection');
        }
        else if (this.refs.select_module.value === 'Open Inspection Image') {
            this.props.history.push('./open_inspection_image');
        }
        else if (this.refs.select_module.value === 'General Service') {
            this.props.history.push('./general_services');
        }
        else if (this.refs.select_module.value === 'Valve Repair') {
            this.props.history.push('./valve_repair');
        }
        else if (this.refs.select_module.value === 'Actuator Repair') {
            this.props.history.push('./actuator_repair');
        }
        else if (this.refs.select_module.value === 'Accessories Detail') {
            this.props.history.push('./accessories_detail');
        }
        else if (this.refs.select_module.value === 'Valve Testing') {
            this.props.history.push('./valve_testing');
        }
        else if (this.refs.select_module.value === 'Repaired Images') {
            this.props.history.push('./repaired_image');
        }
        else if (this.refs.select_module.value === 'Dispatch') {
            this.props.history.push('./dispatch');
        }
    }
    // handleValidation(e) {
    //     let errors = {};
    //     let formIsValid = true;

    //     if (!this.state.diaphragm) {
    //         formIsValid = false;
    //         errors["diaphragm"] = "Required";
    //     }
    //     if (!this.state.fastners) {
    //         formIsValid = false;
    //         errors["fastners"] = "Required";
    //     }
    //     if (!this.state.soft_parts) {
    //         formIsValid = false;
    //         errors["soft_parts"] = "Required";
    //     }
    //     if (!this.state.stem) {
    //         formIsValid = false;
    //         errors["stem"] = "Required";
    //     }
    //     if (!this.state.bushing) {
    //         formIsValid = false;
    //         errors["bushing"] = "Required";
    //     }
    //     this.setState({ errors: errors });
    //     if (e == 1) {
    //         return formIsValid;
    //     }
    // }
    // inputValidation(e) {
    //     if (e === 1) {
    //         this.setState({
    //             diaphragm: this.state.diaphragm,
    //         }, () => { this.handleValidation(0) });
    //     }
    // }
    onChangeactrepair_applicable(e) {
        this.setState({
            is_actuator_applicable: parseInt(e.target.value),
        });
    }
    onchangeDiaphragm(e) {
        this.setState({
            diaphragm: e.target.value,
        }
        // , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangeActSize(e) {
        this.setState({
            act_size: e.target.value,
        });
    }
    onchangeActsizeNote(e) {
        this.setState({
            actsize_notes: e.target.value,
        });
    }
    onchangeDiaphragmNote(e) {
        this.setState({
            diaphragm_notes: e.target.value,
        });
    }
    onchangeFastner(e) {
        this.setState({
            fastners: e.target.value,
        }
        // , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangeFastnerNote(e) {
        this.setState({
            fastners_notes: e.target.value,
        });
    }
    onchangeSoftPart(e) {
        this.setState({
            soft_parts: e.target.value,
        }
        // , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangeSoftPartNote(e) {
        this.setState({
            soft_parts_notes: e.target.value,
        });
    }
    onchangeStem(e) {
        this.setState({
            stem: e.target.value,
        }
        // , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangeStemNote(e) {
        this.setState({
            stem_notes: e.target.value,
        });
    }
    onchangeBushing(e) {
        this.setState({
            bushing: e.target.value,
        }
        // , this.inputValidation(this.state.is_submit_click)
        );
    }
    onchangeSpring(e) {
        this.setState({
            spring: e.target.value,
        });
    }
    onchangeBushingNote(e) {
        this.setState({
            bushing_notes: e.target.value,
        });
    }
    onchangeSpringNote(e) {
        this.setState({
            spring_notes: e.target.value,
        });
    }
    onchangeother(e) {
        this.setState({
            other: e.target.value,
        });
    }
    actuatorRepairSubmit() {
        // e.preventDefault();
        // this.setState({
        //     is_submit_click: 1
        // });
        // if (this.handleValidation(1)) {
            $("#root").addClass("disable_root");
            $("#loader").removeAttr("style").addClass("show_loader");
            let api_token = (localStorage["appState"]) ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            const savejobData = {
                job_id: localStorage['job_id'],
                diaphragm: this.state.diaphragm,
                diaphragm_notes: this.state.diaphragm_notes,
                actuator_size: this.state.act_size,
                actuator_size_notes: this.state.actsize_notes,
                fastners: this.state.fastners,
                fastners_notes: this.state.fastners_notes,
                soft_parts: this.state.soft_parts,
                soft_parts_notes: this.state.soft_parts_notes,
                stem: this.state.stem,
                stem_notes: this.state.stem_notes,
                bushing: this.state.bushing,
                bushing_notes: this.state.bushing_notes,
                spring: this.state.spring,
                spring_notes: this.state.spring_notes,
                other: this.state.other,

                is_actuator_applicable: this.state.is_actuator_applicable,
            }
            axios.post(server_config.API_BASE_URL+"updateActuatorRepair", savejobData, { headers: { "Authorization": "Bearer " + api_token } })
                .then(result => {
                    if(this.state.page_path == "Dashboard"){
                        localStorage['form_submit_alert'] = "Actuator Repair Detail Updated Successfully";
                    }
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                    // alert("Actuator Repair Detail Updated SuccessFully");
                    this.props.history.push("./"+this.state.page_path);
                }).catch(function (error) {
                    alert(error);
                    $("#root").removeClass("disable_root");
                    $("#loader").removeClass("show_loader").css("display", "none");
                });
        // } else {
        //     alert("Please fill up all mandatory fields.");
        //     window.scrollTo(0, 0);
        // }
    }
    render() {
        return (
            <>
                <div className="header pt-5">
                    <Container fluid>
                        <div className="header-body">
                            <Card className="bg-secondary shadow border-0 mb-3">
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                            <label className="form-control-label">Module for Job : <span className="text-danger">{localStorage['job_name']}</span></label>
                                            <select ref="select_module" defaultValue="Actuator Repair" className="form-control form-control-alternative" onChange={this.handleModuleChange}>
                                                <option value="Customer & Job">Customer & Job</option>
                                                <option value="Problems & Open Inspection">Problems & Open Inspection</option>
                                                <option value="Open Inspection Image">Open Inspection Image</option>
                                                <option value="General Service">General Service</option>
                                                <option value="Valve Repair">Valve Repair</option>
                                                <option value="Actuator Repair">Actuator Repair</option>
                                                <option value="Repaired Images">Repaired Images</option>
                                                <option value="Accessories Detail">Accessories Detail</option>
                                                <option value="Valve Testing">Valve Testing</option>
                                                <option value="Dispatch">Dispatch</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                </div>
                {/* Page content */}
                <Container fluid id="actuatorform">
                    <Row>
                        <Col lg="12">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-3">
                                    <div>
                                        <label className="mb-0 job_title">Actuator Repair</label>
                                        <button className="btn-icon btn-sm btn btn-primary back_btn" onClick={this.GoToDashboard} type="button">
                                            <i className="ni ni-bold-left back_icon" />
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg="12">
                                            <FormGroup className="mb-2">
                                                <label className="form-control-label">Is Applicable?</label>
                                                <div className="row">
                                                    <div className="chk_col">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="is_actuator_applicable1"
                                                                name="is_actuator_applicable"
                                                                type="radio"
                                                                value="1"
                                                                checked={(this.state.is_actuator_applicable === 1)}
                                                                onChange={this.onChangeactrepair_applicable}
                                                            />
                                                            <label className="custom-control-label" htmlFor="is_actuator_applicable1">
                                                                Yes
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="chk_col">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="is_actuator_applicable2"
                                                                name="is_actuator_applicable"
                                                                type="radio"
                                                                value="2"
                                                                checked={(this.state.is_actuator_applicable === 2)}
                                                                onChange={this.onChangeactrepair_applicable}
                                                            />
                                                            <label className="custom-control-label" htmlFor="is_actuator_applicable2">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Actuator Size
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="act_size" id="act_size" maxLength="30" name="act_size" value={this.state.act_size} onChange={this.onchangeActSize}>
                                                        <option value="">Select</option>
                                                        <option value="Piston OD">Piston OD</option>
                                                        <option value="Diaphragm OD">Diaphragm OD</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="actsize_notes" value={this.state.actsize_notes} onChange={this.onchangeActsizeNote} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Diaphragm
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="diaphragm" id="diaphragm" maxLength="30" name="diaphragm" value={this.state.diaphragm} onChange={this.onchangeDiaphragm}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: NBR">Replace MOC: NBR</option>
                                                        <option value="Replace MOC: SILICON">Replace MOC: SILICON</option>
                                                        <option value="Replace MOC: VITON">Replace MOC: VITON</option>
                                                        <option value="Replace MOC: PTFE">Replace MOC: PTFE</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    {/* <span className="error_cls">{this.state.errors["diaphragm"]}</span> */}
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="diaphragm_notes" value={this.state.diaphragm_notes} onChange={this.onchangeDiaphragmNote} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Fastners
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="fastners" id="fastners" maxLength="30" name="fastners" value={this.state.fastners} onChange={this.onchangeFastner}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: SS">Replace MOC: SS</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    {/* <span className="error_cls">{this.state.errors["fastners"]}</span> */}
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="fastners_notes" value={this.state.fastners_notes} onChange={this.onchangeFastnerNote} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Soft Parts
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="soft_parts" maxLength="30" id="soft_parts" name="soft_parts" value={this.state.soft_parts} onChange={this.onchangeSoftPart}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Seal Kit Replaced">Seal Kit Replaced</option>
                                                        <option value="Replaced O-Rings">Replaced O-Rings</option>
                                                        <option value="Replaced V-Seal">Replaced V-Seal</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    {/* <span className="error_cls">{this.state.errors["soft_parts"]}</span> */}
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="soft_parts_notes" value={this.state.soft_parts_notes} onChange={this.onchangeSoftPartNote} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Stem
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="stem" maxLength="30" id="stem" name="stem" value={this.state.stem} onChange={this.onchangeStem}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: SS">Replace MOC: SS</option>
                                                        <option value="Polishing">Polishing</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    {/* <span className="error_cls">{this.state.errors["stem"]}</span> */}
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="stem_notes" value={this.state.stem_notes} onChange={this.onchangeStemNote} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Bushing
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="bushing" maxLength="30" id="bushing" name="bushing" value={this.state.bushing} onChange={this.onchangeBushing}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: SS">Replace MOC: SS</option>
                                                        <option value="Replace MOC: PP">Replace MOC: PP</option>
                                                        <option value="Replace MOC: BRASS">Replace MOC: BRASS</option>
                                                        <option value="BUSH MOC : Aluminium">BUSH MOC : Aluminium</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    {/* <span className="error_cls">{this.state.errors["bushing"]}</span> */}
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="bushing_notes" value={this.state.bushing_notes} onChange={this.onchangeBushingNote} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Spring
                                                        </label>
                                                    <select className="form-control form-control-alternative" ref="spring" maxLength="30" id="spring" name="spring" value={this.state.spring} onChange={this.onchangeSpring}>
                                                        <option value="">Select</option>
                                                        <option value="Replace">Replace</option>
                                                        <option value="Replace MOC: SS">Replace MOC: Spring Steel</option>
                                                        <option value="Good Condition">Good Condition</option>
                                                        <option value="N/A">N/A</option>
                                                    </select>
                                                    {/* <span className="error_cls">{this.state.errors["spring"]}</span> */}
                                                    <Input className="form-control-alternative mt-1" placeholder="Notes" maxLength="100" name="spring_notes" value={this.state.spring_notes} onChange={this.onchangeSpringNote} type="text" />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label">Other</label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        rows="2"
                                                        type="textarea"
                                                        name="other"
                                                        value={this.state.other}
                                                        onChange={this.onchangeother}
                                                        maxLength="255"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" className="text-center">
                                                <FormGroup className="btn_formgrp">
                                                    <button className="btn-icon btn btn-outline-danger btn_lefticon" onClick={this.PreviousClick} type="button">
                                                        <i className="fa fa-angle-left" /><span className="btn_text">Previous</span>
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.PrevSaveClick} type="button">
                                                        <i className="fa fa-angle-double-left" /><span className="btn_text">Save & Previous</span>
                                                    </button>
                                                    <button type="button" className="btn btn-danger btn_save" onClick={this.actuatorRepairSubmit}>Save</button>
                                                    <button className="btn-icon btn btn-outline-danger next_prev_btn" onClick={this.NextSaveClick} type="button">
                                                        <span className="btn_text">Save & Next</span><i className="fa fa-angle-double-right" />
                                                    </button>
                                                    <button className="btn-icon btn btn-outline-danger btn_righticon" onClick={this.NextClick} type="button">
                                                        <span className="btn_text">Next</span><i className="fa fa-angle-right" />
                                                    </button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default actuator_repair;
